import { Injectable } from '@angular/core';
import { UserTaskStatus, UserTaskType } from '../../../enums';
import { ConfigService } from "../../config.service";
import { LogBase } from "../../logger.service";
import { WebApi2Service } from "../../web.api2.service";
import { HttpResponse } from '../../../interfaces/result-interfaces';
import { AddAdhocUserTaskRequest } from '../../../interfaces/user-tasks/adhoc-user-tasks.interface';
@Injectable({
  providedIn: 'root'
})

export class UserTaskApiService {

  private serverUrl = `${this.config.getServerUrl()}api/userTask/`;

  constructor(
    private config: ConfigService,
    private log: LogBase,
    private webApi: WebApi2Service
  ) { }

  // Endpoint: api/userTask/GetUserTaskSummary
  // Controller Name: UserTaskController.cs
  // Proc Name: pr_GetUserTaskSummary
  public async GetUserTaskSummary(): Promise<HttpResponse | null> {
    try {
      const url = `${this.serverUrl}GetUserTaskSummary`;
      return await this.webApi.get(null, url);
    } catch (error) {
      this.log.error(error);
    }
    return null;
  }

  // Endpoint: api/userTask/GetUserTasksByUserTaskTypeId
  // Controller Name: UserTaskController.cs
  public async GetUserTasksByUserTaskTypeId(userTaskTypeId: number): Promise<HttpResponse | null> {
    try {
      const url = `${this.serverUrl}GetUserTasksByUserTaskTypeId?userTaskTypeId=${userTaskTypeId}`;
      return await this.webApi.get(null, url);
    } catch (error) {
      this.log.error(error);
    }
    return null;
  }

  // Endpoint: api/userTask/GetUserTaskByFileId
  // Controller Name: UserTaskController.cs
  // Proc Name: pr_GetUserTasksByGenericId
  public async GetUserTaskByFileId(fileId: number): Promise<HttpResponse | null> {
    try {
      const url = `${this.serverUrl}GetUserTaskByFileId?fileId=${fileId}`;
      return await this.webApi.get(null, url);
    } catch (error) {
      this.log.error(error);
    }
    return null;
  }

  // Endpoint: api/userTask/UpdateUserTask
  // Controller Name: UserTaskController.cs
  public async UpdateUserTask(userTaskId: number, userTaskStatusId: UserTaskStatus): Promise<HttpResponse | null> {
    try {
      const url = `${this.serverUrl}UpdateUserTask?userTaskId=${userTaskId}&userTaskStatusId=${userTaskStatusId}`;
      return await this.webApi.get(null, url);
    } catch (error) {
      this.log.error(error);
    }
    return null;
  }

  // Endpoint: api/userTask/addAdhocUserTask
  // Controller Name: UserTaskController.cs
  public async AddAdhocUserTask(addAdhocUserTaskPayload: AddAdhocUserTaskRequest): Promise<HttpResponse | null> {
    try {
      const url = `${this.serverUrl}AddAdhocUserTask`;
      return await this.webApi.post(null, url, addAdhocUserTaskPayload as any);
    } catch (error) {
      this.log.error(error);
    }
    return null;
  }

  // Endpoint: api/userTask/GetAdhocUserTask
  // Controller Name: UserTaskController.cs
  // Proc Name: pr_getAdhocUserTask
  public async GetAdhocUserTask(userTaskId: number): Promise<HttpResponse | null> {
    try {
      const url = `${this.serverUrl}GetAdhocUserTask?userTaskId=${userTaskId}`;
      return await this.webApi.get(null, url);
    } catch (error) {
      this.log.error(error);
    }
    return null;
  }

  // Endpoint: api/userTask/reassignUserTasks
  // Controller Name: UserTaskController.cs
  // Proc Name: pr_AssignUserTask
  public async reassignUserTasks(payload: any): Promise<HttpResponse | null> {
    try {
      const url = `${this.serverUrl}ReassignUserTasks`;
      return await this.webApi.post(null, url, payload);
    } catch (error) {
      this.log.error(error);
      return null;
    }
  }

  // Endpoint: api/userTask/GetAdhocUserTaskTypes
  // Controller Name: UserTaskController.cs
  // Proc Name: pr_getAdhocUserTaskTypes
  public async getAdhocUserTaskTypes(): Promise<HttpResponse | null> {
    try {
      const url = `${this.serverUrl}GetAdhocUserTaskTypes`;
      return await this.webApi.get(null, url);
    } catch (error) {
      this.log.error(error);
      return null;
    }
  }

  // Endpoint: api/userTask/getVoageUserTaskSummary
  // Controller Name: UserTaskController.cs
  // Proc Name: pr_getVoageUserTaskSummary
  public async getVoageUserTaskSummary(voyageId: number): Promise<HttpResponse | null> {
    try {
      const apiUrl = `${this.serverUrl}getVoageUserTaskSummary?voyageId=${voyageId}`;
      return await this.webApi.get(null, apiUrl);
    } catch (error) {
      this.log.error(error);
    }
    return null;
  }

  // Endpoint: api/userTask/GetUserTasksByVoyageIdAndUserTaskTypeId
  // Controller Name: UserTaskController.cs
  // Proc Name: pr_GetUserTasksByVoyageIdAndUserTaskTypeId
  public async GetUserTasksByVoyageIdAndUserTaskTypeId(voyageId: number, userTaskTypeId: UserTaskType): Promise<HttpResponse | null> {
    try {
      const url = `${this.serverUrl}GetUserTasksByVoyageIdAndUserTaskTypeId?voyageId=${voyageId}&userTaskTypeId=${userTaskTypeId}`;
      return await this.webApi.get(null, url);
    } catch (error) {
      this.log.error(error);
      return null;
    }
  }

  // Endpoint: api/userTask/GetUserTaskByFileId
  // Controller Name: UserTaskController.cs
  // Proc Name: pr_GetUserTasksByGenericId
  public async DispatchNotifyClientReminder(fileId: number): Promise<HttpResponse | null> {
    try {
      const url = `${this.serverUrl}DispatchNotifyClientReminder?fileId=${fileId}`;
      return await this.webApi.post(null, url, null);
    } catch (error) {
      this.log.error(error);
    }
    return null;
  }
}