import { Dropdown, WATermsAndConditions } from "../../interfaces/global.interfaces";
import { Component, OnInit, ViewChild } from "@angular/core";
import { ApiService } from "../../services/api.service";
import { ActivatedRoute } from "@angular/router";
import { LogBase } from "../../services/logger.service";
import { MenuItem, TabView } from "primeng";
import { Router } from "@angular/router";
import { ConfirmationService } from "primeng";
import { MessageService } from "primeng/api";
import { ConfigService } from "../../services/config.service";
import * as moment from "moment";
import { Title } from "@angular/platform-browser";
import { Injectable } from "@angular/core";
import { StatementApiService } from "../../services/api/finance/statement-api.service";
import { DocumentApiService } from "../../services/api/document-api.service";
import { WebApi2Service } from "./../../services/web.api2.service";
import { FileApiService } from "../../services/api/file-api.service";
import { OutstandingBalance } from "../../interfaces/finance-interfaces";
import { ClearingAgent, ClearingStatus, Groups, InvoiceType } from "../../enums";
import { AuthenticationService } from "../../services/api/auth/authentication.service";
import { InvoiceApiService } from "../../services/api/finance/invoice-api.service";
import { ReferenceApiService } from "../../services/api/reference-api.service";
import { TaskApiService } from "../../services/api/task-api.service";

@Component({
  selector: "app-file",
  templateUrl: "./file.component.html",
  styleUrls: ["./file.component.scss"],
  providers: [ConfirmationService],
})
@Injectable({
  providedIn: "root",
})
export class FileViewComponent implements OnInit {

  firstLoad: boolean = false;
  file: any;
  fileId: any;
  showSpinner: boolean = true;
  fileEditMode: boolean = false;
  fileInformationSpinner: boolean = false;
  convertFile: boolean = false;
  convertFileDialogText: string;
  openFileDialog: boolean = false;
  fileOpen: boolean = false;
  fileCancelled: boolean = false;
  readOnly: boolean = false;
  canCancelFile: boolean = false;
  fileCancelledLabel: string = "";
  HBLDialog: boolean = false;
  AssignToDealerialog: boolean = false;
  HBLMultiItemDialog: boolean = false;
  refreshFinanceTab: boolean = false;
  HBLversions: Dropdown[] = [
    { label: "Original", value: 1 },
    { label: "Second Original", value: 2 },
    { label: "Third Original", value: 3 },
    { label: "Copy", value: 4 },
  ];
  selectedHBLversions: any[] = [];
  closeFileDialog: boolean = false;
  closeRestartDialog: boolean = false;
  acceptTermsDialog: boolean = false;
  resetRouteDialog: boolean = false;
  financeUser: boolean = this.authService.canEdit(Groups.ACCOUNTS);
  MBL: boolean = true;
  fileOutStandingAmounts: OutstandingBalance[];
  HasClearingAgent: boolean = false;
  showClearingAgentWarning: boolean = false;
  EmailOriginal: boolean = true;
  items: MenuItem[];
  actions: MenuItem[] = [
    {
      label: "Close File",
      icon: "far fa-times-circle",
      command: () => {
        this.closeFileDialog = true;
      },
    },
    {
      label: "Restart Workflow for File",
      icon: "fas fa-sync-alt",
      command: () => {
        this.closeRestartDialog = true;
      },
    },
    {
      label: "Reset Route for File",
      icon: "fas fa-sync-alt",
      command: () => {
        this.resetRouteDialog = true;
      },
    },
  ];

  borderAgents: any[];
  courierCompanies: any[];
  agents: any[];
  selectedAgent: any;
  fileDocumentSources: Dropdown[];
  cifVal: any;
  consigneeEmail: string = "";
  consigneeTelephone: string = "";
  consigneeCellphone: string = "";
  consigneeAddress: string = "";
  consigneeCity: string = "";
  consigneeCountry: string = "";
  activeIndex: number = 0;
  closeRestartCIFDialog: boolean = false;
  displayANFModal: boolean = false;
  sendANFEmail: boolean = false;
  displayDROModal: boolean = false;
  sendDROEmail: boolean = false;
  displayHandoverModal: boolean = false;
  sendHandoverEmail: boolean = false;
  agentdiv: string = "p-col-4";
  agentdivVisible: boolean = true;
  termsAndconditions: WATermsAndConditions;
  termsAndConditions: string = "";
  storageInvoiceDialog: boolean = false;
  actionSpinner: boolean = false;
  storageInvoiceDate: string = moment().format('YYYY/MM/DD');
  termsToolTip: string = "";
  approvedToolTip: string = "";
  paidTooltip: string = "";
  paidLabel: string = "";
  deepLinkDialog: boolean = false;
  deepLink: string = "";
  form: any = {};
  onLoad: number = 0;
  fileTasks: { supported: boolean; tasks: any[]; } = {
    supported: false,
    tasks: [],
  };

  resetReason: string = "";

  get isAmountOwed(): boolean {
    return this.fileOutStandingAmounts.every(f => f.amountOwed);
  }

  constructor(
    private api: ApiService,
    private route: ActivatedRoute,
    private log: LogBase,
    private router: Router,
    private authService: AuthenticationService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private config: ConfigService,
    private titleService: Title,
    private referenceApiService: ReferenceApiService,
    private documentService: DocumentApiService,
    private statementApi: StatementApiService,
    private webApi2: WebApi2Service,
    private fileApiService: FileApiService,
    private taskApiService: TaskApiService,
    private invoiceApiService: InvoiceApiService
  ) {
    this.titleService.setTitle("AVECS File");
  }

  async ngOnInit() {
    try {
      this.showSpinner = true;
      this.route.params.subscribe((params) => {
        this.fileId = params.fileId;
      });

      if (!this.fileId) {
        this.route.queryParams.subscribe((params) => {
          this.fileId = params.query;
        });
      }
      await this.getFile();
      await this.setFileTasks();

      await this.setupFileData();
      await this.getTermsAndConditions();

      if (this.file != undefined) {
        this.showSpinner = false;
      } else {
        this.showSpinner = false;
        this.messageService.add({
          severity: "error",
          summary: "Error",
          detail: "Failed To Fetch File Data",
        });
      }
    } catch (error) {
      this.showError("There was an error", error);
      this.log.error(error);
    }
  }

  async getFile() { //////
    const file = await this.api.getFileById(this.fileId);

    if (!file) {
      this.toastMessage('error', 'Failed to get file. Please contact support', '');
      return;
    }

    this.file = file;
    
    if (this.file.ClearingAgentId && this.file.ClearingAgentId > 0) {
      this.HasClearingAgent = true;
    }
    await this.checkBalance();
    await this.setupDropDownMenuItems();
  }

  checkIfPaid() {
    const overpaid = this.fileOutStandingAmounts.every(f => f.amountOutStanding < 0);
    const paid = this.fileOutStandingAmounts.every(f => f.amountOutStanding === 0);

    if (!this.fileOutStandingAmounts || this.fileOutStandingAmounts.length === 0) return this.notPaid();
    else if (overpaid) return this.overPaid();
    else if (paid) return this.paid();
    else if (!overpaid && !paid) return this.notPaid();
  }

  notPaid() {
    this.paidTooltip = "Account not paid";
    this.paidLabel = "Not Paid";
    return "DarkOrange";
  }

  paid() {
    this.paidTooltip = "Account up to date";
    this.paidLabel = "Paid";
    return "Green";
  }

  overPaid() {
    this.paidTooltip = "Account over paid";
    this.paidLabel = "Over Paid";
    return "#e8002c";
  }

  checkTerms() {
    //TODO
    // !this.fileOutStandingAmounts.amountOwed
    if (!this.file.CIF && (!this.fileOutStandingAmounts || this.fileOutStandingAmounts.length === 0)) {
      this.termsToolTip =
        "There are no invoices for this file. Can't accept terms and conditions";
      this.termsAndConditions = "Terms & Conditions - No Invoices";
      return "#8000ff";
    } else if (this.termsAndconditions.acceptTerms == 0) {
      this.termsToolTip = "Pending";
      this.termsAndConditions = "Terms & Conditions Pending";
      return "DarkOrange";
    } else if (this.termsAndconditions.acceptTerms == 2) {
      this.termsToolTip = "Declined";
      this.termsAndConditions = "Terms & Conditions Declined";
      return "Red";
    } else if (this.termsAndconditions.acceptTerms == 1) {
      this.termsToolTip = "Accepted";
      this.termsAndConditions = "Terms & Conditions Accepted";
      return "Green";
    } else {
      this.termsToolTip = "Pending";
      this.termsAndConditions = "Terms & Conditions Pending";
      return "DarkOrange";
    }
  }

  showAcceptTermsDialog() {
    if (this.termsAndconditions.acceptTerms == 0) {
      this.acceptTermsDialog = true;
    }
  }

  checkFilesApproved() {
    if (this.termsAndconditions.filesApproved == 0) {
      this.approvedToolTip = "Files Awaiting Approval";
      return "DarkOrange";
    } else {
      this.approvedToolTip = "Files Approved";
      return "Green";
    }
  }

  async setupFileData() {
    if (this.file.ConsigneeEmail.length > 0)
      this.consigneeEmail += this.file.ConsigneeEmail;
    if (this.file.ConsigneeEmail2.length > 0)
      this.consigneeEmail += " / " + this.file.ConsigneeEmail2;

    if (this.file.ConsigneeTelephone.length > 0)
      this.consigneeTelephone += this.file.ConsigneeTelephone;
    if (this.file.ConsigneeTelephone2.length > 0)
      this.consigneeTelephone += " / " + this.file.ConsigneeTelephone2;
    if (this.file.ConsigneeTelephone3.length > 0)
      this.consigneeTelephone += " / " + this.file.ConsigneeTelephone3;

    if (this.file.ConsigneeCellphone.length > 0)
      this.consigneeCellphone += this.file.ConsigneeCellphone;
    if (this.file.ConsigneeCellphone2.length > 0)
      this.consigneeCellphone += " / " + this.file.ConsigneeCellphone2;

    if (this.file.ConsigneeAddress.length > 0)
      this.consigneeAddress += this.file.ConsigneeAddress;
    if (this.file.ConsigneeAddress2.length > 0)
      this.consigneeAddress += " / " + this.file.ConsigneeAddress2;
    if (this.file.ConsigneeAddress3.length > 0)
      this.consigneeAddress += " / " + this.file.ConsigneeAddress3;

    this.consigneeCity = this.file.ConsigneeCity;
    this.consigneeCountry = this.file.ConsigneeCountry;

    if (this.file.AgentName.length == 0) {
      this.agentdiv = "p-col-6";
      this.agentdivVisible = false;
    } else {
      this.agentdiv = "p-col-4";
      this.agentdivVisible = true;
    }

    if (this.file.MBL && this.file.MBL.length > 0 && this.file.MBL != "#N/A")
      this.MBL = true;

    // if (this.file.Cancelled || this.file.Complete) this.readOnly = true;
    if (this.file.Complete) {
      this.fileCancelledLabel = "CLOSED";
    }
    if (this.file.Cancelled) {
      this.fileCancelledLabel = "CANCELLED";
    }
  }

  handleChange(e) {
    this.activeIndex = e.index;
  }

  shortcut(index) {
    if (this.activeIndex == index) {
      this.scroll();
      return;
    }
    this.handleChange({ index });
  }

  scroll() {
    if (!this.firstLoad) {
      this.firstLoad = true;
      return;
    }
    setTimeout(
      () =>
        window.scroll({
          top: document.body.scrollHeight - window.innerHeight,
          behavior: "smooth",
        }),
      105
    );
  }

  async getConsigneeOTP() {
    return (
      await this.api.getWebsiteOTPForDeeplink(this.file.ConsigneeLegalEntityId)
    ).result;
  }

  async createConsigneeDeepLink() {
    this.deepLink = `${this.config.AvecsDeepLink}${await this.getConsigneeOTP()}/1/${this.fileId}`;
    this.deepLinkDialog = true;
  }

  copyDeepLink() {
    let tempLink = this.deepLink;
    navigator.clipboard.writeText(this.deepLink);
    this.deepLink = "Copied";
    setTimeout(() => (this.deepLink = tempLink), 1000);
  }

  async determineInvoiceWordingAndCheckIfCanCreateTheInvoice() {
    let invoiceType: InvoiceType = null;
    let txt = '';
    let isThereAClearingAgent = true;

    if (this.file.ClearingStatusId == ClearingStatus.AVECS) {
      invoiceType = InvoiceType.RORO;
      txt = 'AVECS';
    } else if (this.file.ClearingStatusId == ClearingStatus.PARTIAL_HANDOVER) {
      if (this.file.ClearingAgentId != ClearingAgent.AVECS_CLEARING_AGENT) {
        invoiceType = InvoiceType.PARTIAL_HANDOVER;
        txt = 'Partial Handover';
      } else {
        invoiceType = InvoiceType.RORO;
        txt = 'AVECS';
      }
      if (!this.file.ClearingAgentId) isThereAClearingAgent = false;
    } else if (this.file.ClearingStatusId == ClearingStatus.HANDOVER) {
      invoiceType = InvoiceType.HANDOVER;
      txt = 'Handover';
    } else {
      this.toastMessage('error', 'Failed to determine the invoice wording. Please contact support.', '');
      return;
    }
    const resp = await this.invoiceApiService.IsThereAnActiveInvoiceByFileIdAndInvoiceType(this.fileId, invoiceType);

    let disableInvoice = false;
    let disableAdhocInvoice = false;
    let disableStorageInvoice = false;

    //if there is no clearing agent then will disable the create invoice item
    if (!isThereAClearingAgent) {
      disableInvoice = true;
      disableAdhocInvoice = true;
      disableStorageInvoice = true;
    }
    //if we got back an invoice id 
    else if (resp.result) disableInvoice = true;

    return {
      txt: txt,
      disableInvoice: disableInvoice,
      disableAdhocInvoice: disableAdhocInvoice,
      disableStorageInvoice: disableStorageInvoice
    };
  }

  async setupDropDownMenuItems() {
    const invoiceWording = await this.determineInvoiceWordingAndCheckIfCanCreateTheInvoice();

    this.items = [
      {
        label: `${invoiceWording.txt} Invoice`,
        icon: "fas fa-file-invoice",
        command: () => {
          window.open(`invoice/create/0/${this.fileId}`);
        },
        disabled: !this.financeUser || invoiceWording.disableInvoice || this.file.CIF || this.file.Cancelled || this.file.Complete,
      },
      {
        label: `Adhoc ${invoiceWording.txt} Invoice`,
        icon: "fas fa-file-invoice",
        command: () => {
          window.open(`invoice/create/1/${this.fileId}`);
        },
        disabled: !this.financeUser || invoiceWording.disableAdhocInvoice || this.file.Cancelled || this.file.Complete,
      },
      {
        label: "Storage Invoice",
        icon: "fas fa-file-invoice",
        command: () => {
          this.storageInvoiceDialog = true;
        },
        disabled: !this.financeUser || invoiceWording.disableStorageInvoice || this.file.Cancelled || this.file.Complete,
      },
      {
        label: "Statement",
        icon: "fas fa-file-invoice",
        command: () => {
          this.createStatement(this.fileId);
        },
        disabled: this.canCreateStatement(),
      },
      {
        label: "Arrival Notification",
        icon: "fas fa-file-invoice",
        command: () => {
          this.createArrivalNotification();
        },
        disabled: this.file.Cancelled || this.file.Complete,
      },
      {
        label: "Hand Over Letter",
        icon: "fas fa-file-invoice",
        command: () => {
          if (this.HasClearingAgent == true) {
            this.createHandOverLetter();
          } else {
            this.showClearingAgentWarning = true;
          }
        },
        disabled: this.file.Cancelled || this.file.Complete,
      },
      {
        label: "Delivery Release Order",
        icon: "fas fa-file-invoice",
        command: () => {
          this.createDeliveryReleaseOrder();
        },
        disabled: this.file.Cancelled || this.file.Complete,
      },
      {
        label: "Letter of Authority",
        icon: "fas fa-file-invoice",
        command: () => {
          this.createLetterOfAuthorityToClearVehicle(this.fileId);
        },
        disabled: this.file.Cancelled || this.file.Complete,
      },
      {
        label: "House Bill RORO",
        icon: "fas fa-file-invoice",
        command: () => {
          this.HBLDialog = true;
        },
        disabled: !this.MBL || this.file.Cancelled || this.file.Complete,
      },
      {
        label: "House Bill Other",
        icon: "fas fa-file-invoice",
        command: () => {
          this.HBLMultiItemDialog = true;
        },
        disabled: !this.MBL || this.file.Cancelled || this.file.Complete,
      },
      {
        label: "Window Sheet",
        icon: "fas fa-file-invoice",
        command: () => {
          this.createWindowSheet(this.fileId);
        },
        disabled: this.file.Cancelled || this.file.Complete,
      },
      {
        label: "File Cover (Vehicle)",
        icon: "fas fa-car",
        command: () => {
          this.generateFileCoverVehicle(this.fileId);
        },
        disabled: this.file.Cancelled || this.file.Complete,
      },
      {
        label: "File Cover (Container)",
        icon: "fas fa-truck",
        command: () => {
          this.generateFileCoverContainer(this.fileId);
        },
        disabled: this.file.Cancelled || this.file.Complete,
      },
      {
        label: "Clearing and Forwarding Instructions",
        icon: "fas fa-info-circle",
        command: () => {
          this.generateImportersClearingAndForwardingInstructions();
        },
        disabled: this.file.Cancelled || this.file.Complete,
      },
    ];

    this.actions = [];

    if (this.file.Complete == 1) {
      this.actions.push({
        label: "Open File",
        icon: "fas fa-refresh",
        command: () => {
          this.fileOpen = true;
        },
      });
    }
    if (this.file.Complete == 0) {
      this.actions.push({
        label: "Close File",
        icon: "far fa-times-circle",
        command: () => {
          this.closeFileDialog = true;
        },
      });
      this.actions.push({
        label: "Restart Workflow for File",
        icon: "fas fa-sync-alt",
        command: () => {
          this.closeRestartDialog = true;
        },
      });

      if (this.authService.canEdit(Groups.FILE_MANAGEMENT) && this.file.OnLoad != 1 || this.authService.canEdit(Groups.SYSTEM_ADMIN) && this.file.OnLoad != 1) {
        this.actions.push({
          label: "Reset Route for File",
          icon: "fas fa-sync-alt",
          command: () => {
            this.resetRouteDialog = true;
          },
        });
      }

      if (this.file.AgentName.length == 0 && this.file.ClearingStatusId == ClearingStatus.AVECS) {
        this.actions.push({
          label: "Assign to Agent/Dealer",
          icon: "far fa-address-book",
          command: () => {
            this.AssignToDealerialog = true;
            this.convertFileDialogText = "Assign to Agent/Dealer";
          },
        });
      }
      this.actions.push({
        label: "Create cosignee deep link",
        icon: "fa fa-globe",
        command: () => {
          this.createConsigneeDeepLink();
        }
      });
      if (this.file.ClearingStatusId == ClearingStatus.AVECS) {
        this.actions.push({
          label: "Convert File to Handover",
          icon: "fa fa-refresh",
          command: () => {
            this.convertFileDialogText = "Convert File to Handover ?";
            this.convertFile = true;
            this.file.ClearingStatusId = ClearingStatus.HANDOVER;
          },
        });
        this.actions.push({
          label: "Convert File to Partial Handover",
          icon: "fa fa-refresh",
          command: () => {
            this.convertFileDialogText = "Convert File to Partial Handover?";
            this.convertFile = true;
            this.file.ClearingStatusId = ClearingStatus.PARTIAL_HANDOVER;
          },
        });
      } else if (this.file.ClearingStatusId == ClearingStatus.HANDOVER) {
        this.actions.push({
          label: "Convert File to RORO",
          icon: "fa fa-refresh",
          command: () => {
            this.convertFile = true;
            this.file.ClearingStatusId = ClearingStatus.AVECS;
            this.convertFileDialogText = "Convert File to DBN?";
          },
        });
        this.actions.push({
          label: "Convert File to Partial Handover",
          icon: "fa fa-refresh",
          command: () => {
            this.convertFile = true;
            this.file.ClearingStatusId = ClearingStatus.PARTIAL_HANDOVER;
            this.convertFileDialogText = "Convert File to Partial Handover?";
          },
        });
      } else if (this.file.ClearingStatusId == ClearingStatus.PARTIAL_HANDOVER) {
        this.actions.push({
          label: "Convert File to RORO",
          icon: "fa fa-refresh",
          command: () => {
            this.convertFile = true;
            this.file.ClearingStatusId = ClearingStatus.AVECS;
            this.convertFileDialogText = "Convert File to DBN?";
          },
          disabled: this.file.ClearingAgentId != ClearingAgent.AVECS_CLEARING_AGENT
        });
        this.actions.push({
          label: "Convert File to Handover",
          icon: "fa fa-refresh",
          command: () => {
            this.convertFile = true;
            this.file.ClearingStatusId = ClearingStatus.HANDOVER;
            this.convertFileDialogText = "Convert File to RE?";
          }
        });
      }
    }
  }

  canCreateStatement = () =>
    !this.financeUser ||
    !this.fileOutStandingAmounts;

  //TODO
  // !this.fileOutStandingAmounts.amountOwed

  async convertFileClearingStatus() {
    this.showSpinner = true;
    this.convertFile = false;
    const retval = await this.api.convertFileClearingStatus(this.file.ClearingStatusId, this.file.Id);
    if (!retval) this.toastMessage("error", "Failed to convert file, plase contact support.", "");
    else if (retval.errorCode === -1) this.toastMessage("warn", retval.errorMessage, "");
    else if (retval.errorCode !== 0) this.toastMessage("error", retval.errorMessage, "");
    else {
      this.toastMessage("success", retval.errorMessage, "");
      setTimeout(() => { this.router.navigate(['/task-list']); }, 1200);
    }
    this.showSpinner = false;
  }

  async RestartWorkflow() {
    this.showSpinner = true;
    this.closeRestartDialog = false;
    let retval = await this.api.restartFileWorkflow(
      this.file.ClearingStatusId,
      this.file.Id
    );
    if (retval) {
      if (retval.errorCode == 0) {
        this.toastMessage("success", retval.errorMessage, "");
      } else {
        this.toastMessage("error", retval.errorMessage, "");
      }
    } else {
      this.toastMessage("error", "Failed to restart work flow. Please contact support.", "");
    }
    this.showSpinner = false;
  }

  async resetRoute() {
    this.showSpinner = true;
    this.resetRouteDialog = false;
    const retval = await this.taskApiService.resetRouteForFile(this.file.Id, this.resetReason);
    if (!retval) this.toastMessage("error", 'Failed to reset route for file. Please contact support', "");
    else if (retval.errorCode !== 0) this.toastMessage("error", retval.errorMessage, "");
    else this.toastMessage("success", retval.errorMessage, "");
    this.showSpinner = false;
  }

  async openFile() {
    this.showSpinner = true;
    this.fileOpen = false;
    const retval = await this.taskApiService.openFile(this.file.Id);
    if (!retval) this.toastMessage("error", 'Failed to reopen file. Please contact support', "");
    else if (retval.errorCode !== 0) this.toastMessage("error", retval.errorMessage, "");
    else {
      this.toastMessage("success", retval.errorMessage, "");
      setTimeout(() => { this.router.navigate(['/task-list']); }, 1200);
    }
    this.showSpinner = false;
  }

  cancelConversion() {
    this.convertFile = false;
  }

  cancelCancel() {
    this.canCancelFile = false;
  }

  async closeFile() {
    this.showSpinner = true;
    this.closeFileDialog = false;
    const retval = await this.taskApiService.closeFile(this.file.Id);
    if (!retval) this.toastMessage("error", 'Failed to close file. Please contact support', "");
    else if (retval.errorCode !== 0) this.toastMessage("error", retval.errorMessage, "");
    else {
      this.fileCancelledLabel = "CLOSED";
      this.file.Complete = true;
      this.toastMessage("success", retval.errorMessage, "");
      setTimeout(() => { this.router.navigate(['/task-list']); }, 1200);
    }
    this.showSpinner = false;
  }

  closeCancelFile() {
    this.closeFileDialog = false;
  }

  closeRestartWorkflow() {
    this.closeRestartDialog = false;
    this.closeRestartCIFDialog = false;
  }

  routeReset() {
    this.resetRouteDialog = false;
  }

  async closeAssignFileToAgent() {
    this.showSpinner = true;

    try {
      let retval = await this.api.assignFileToAgent(
        this.selectedAgent,
        this.file.Id
      );
      if (retval) {
        await this.setupFileData();
        this.messageService.add({
          severity: "success",
          summary: "Agent added",
          detail: "An Agent/Dealer was assigned to this file",
        });
      }
    } catch (error) {
      this.log.info(error);
    }

    this.AssignToDealerialog = false;
    this.showSpinner = false;
  }

  async closeHBL() {
    this.showSpinner = true;

    for (const hbl of this.selectedHBLversions) {
      await this.generateHBL(this.fileId, hbl.value, this.EmailOriginal);
    }
    this.showSpinner = false;
    this.HBLDialog = false;
  }

  async closeMultiLineHBL() {
    this.showSpinner = true;

    for (const hbl of this.selectedHBLversions) {
      await this.generateMultiLineHBL(
        this.fileId,
        hbl.value,
        this.EmailOriginal
      );
    }
    this.showSpinner = false;
    this.HBLMultiItemDialog = false;
  }

  async refreshFinance() {
    await this.checkBalance();
    await this.setupDropDownMenuItems();
  }

  closeCancelHBL() {
    this.HBLDialog = false;
  }

  async setFileEdit(event: MouseEvent) {
    const targetEvent = event.target as HTMLElement;

    if (targetEvent.id != 'parent-click') {
      this.fileEditMode = false;
      return;
    }

    this.form = {
      ...this.file,
      CIF: this.file.CIF,
    };

    this.fileInformationSpinner = true;
    this.fileEditMode = true;

    if (!this.borderAgents || this.borderAgents.length == 0) this.borderAgents = await this.api.getBorderAgents();
    if (!this.agents || this.agents.length == 0) this.agents = await this.api.getAgents();
    if (!this.courierCompanies || this.courierCompanies.length == 0) this.courierCompanies = await this.api.getCourierCompanies();
    if (!this.fileDocumentSources || this.fileDocumentSources.length == 0) this.fileDocumentSources = await this.referenceApiService.getDocumentSources();

    this.fileInformationSpinner = false;
  }

  momentOrNull(date) {
    return date != null ? moment(date).format("YYYY-MM-DD") : null;
  }

  async updateFileInfo() {
    this.fileInformationSpinner = true;
    try {
      const payload = {
        FileId: this.form.Id,
        FileDocumentSourceId: this.form.FileDocumentSourceId,
        CourierId: this.form.CourierId,
        TrackingNumber: this.form.TrackingNumber,
        BorderAgentId: this.form.BorderAgentId,
        CIF: this.form.CIF,
      };

      const res = await this.fileApiService.updateFileInformation(payload);
      if (!res) this.toastMessage('error', 'Failed to update file information. Please contact support', '');
      else if (res.errorCode != 0) this.toastMessage('error', res.errorMessage, '');
      else {
        this.file = await this.api.getFileById(this.fileId);
        await this.setFileTasks();
        this.toastMessage('success', res.errorMessage, '');
      }
    } catch (error) {
      this.log.error(error);
    }
    this.fileEditMode = false;
    this.fileInformationSpinner = false;
  }

  async setFileTasks() {
    let resp = await this.api.getFileTasksComplete(this.fileId);
    if (typeof resp === "string") {
      this.fileTasks.supported = false;
    } else {
      this.fileTasks.supported = true;
      this.fileTasks.tasks = resp;
    }
  }

  async createStatement(fileid: number) {
    try {
      let retval = await this.statementApi.GenerateStatementForFile(fileid);
      if (retval) {
        if (retval.errorCode == 0) {
          this.toastMessage("success", retval.errorMessage, "");
        } else {
          this.toastMessage("error", retval.errorMessage, "");
        }
      } else {
        this.toastMessage(
          "error",
          "Failed to generate statement. Please contact support.",
          ""
        );
      }
    } catch (error) {
      this.showError("Error creating Statement", error);
      this.log.error(error);
    }
  }

  async generateHBL(fileid: number, version: number, emailOriginal: boolean) {
    var res = await this.api.generateHBL(fileid, version, emailOriginal);
    if (res) {
      this.toastMessage("success", "", "New HBL Generated");
    } else {
      this.toastMessage("error", "", "HBL Could not be generated");
    }
  }

  async generateMultiLineHBL(
    fileid: number,
    version: number,
    emailOriginal: boolean
  ) {
    var res = await this.api.generateHBLMultiItem(
      fileid,
      version,
      emailOriginal
    );
    if (res) {
      this.messageService.add({
        severity: "success",
        summary: "",
        detail: "New HBL Generated",
      });
    } else {
      this.messageService.add({
        severity: "error",
        summary: "",
        detail: "HBL Could not be generated",
      });
    }
  }

  consigneeRoute() {
    this.router.navigate([
      `/manage-legal-entity/${this.file.ConsigneeLegalEntityId}/${this.file.ConsigneeLegalEntityTypeId}`,
    ]);
  }

  async showAgent() {
    if (this.file.AgentLegalEntityTypeId == 0) {
      this.toastMessage("error", "There is no agent for this file.", "");
      return;
    }
    this.router.navigate([
      `/manage-legal-entity/${this.file.AgentLegalEntityId}/${this.file.AgentLegalEntityTypeId}`,
    ]);
  }

  async showClient() {
    this.router.navigate([
      `/manage-legal-entity/${this.file.ClientLegalEntityId}/${this.file.ClientLegalEntityTypeId}`,
    ]);
  }

  is(i: number) {
    return i === this.activeIndex;
  }

  CancelTask() {
    this.router.navigate(["task-list"]);
  }

  async showError(friendlyMessage: any, errorMessage: any) {
    this.confirmationService.confirm({
      message:
        friendlyMessage + ". Do you want to email this error to Support?",
      header: "Error",
      icon: "pi pi-exclamation-triangle",
      accept: () => {
        var componentName = this.route.routeConfig.component.name;
        var userName = this.authService.getUsername;
        var date = new Date();
        var emailBody: string = "Component Name: File Summary - File View<br/>";
        emailBody += "Component: " + componentName + "<br/>";
        emailBody += "User: " + userName + "<br/>";
        emailBody += "Date: " + date + "<br/>";
        emailBody += "Error: " + friendlyMessage + "<br/>";
        emailBody += "ErrorMessage: " + errorMessage + "<br/>";

        var subject: string =
          "NovaDT Error Message - Component: File Summary - File View";
        var res = this.api.sendFreeTextEmail(
          this.config.supportEmail,
          "system@avecs.co.za",
          emailBody,
          subject,
          this.fileId,
          -1
        );
      },
    });
  }

  async createArrivalNotification() {
    try {
      const payload = {
        FileId: this.fileId,
      };
      let res = await this.api.createArrivalNotification(
        payload,
        this.sendANFEmail
      );
      this.displayANFModal = false;
      this.sendANFEmail = false;

      if (!res) {
        this.messageService.add({
          severity: "error",
          summary: "Error",
          detail: "Failed to Create Arrival Notification",
        });
      } else {
        this.messageService.add({
          severity: "success",
          summary: "Success",
          detail: "Arrival Notification created and saved in the File System",
        });
      }
    } catch (err) {
      this.log.error(err);
    }
  }

  async createHandOverLetter() {
    try {
      const payload = {
        FileId: this.fileId,
      };
      let res = await this.api.createHandOverLetter(
        payload,
        this.sendHandoverEmail
      );
      this.displayHandoverModal = false;
      this.sendHandoverEmail = false;

      if (!res) {
        this.messageService.add({
          severity: "error",
          summary: "Error",
          detail: "Failed to Create Hand Over Letter",
        });
      } else {
        this.messageService.add({
          severity: "success",
          summary: "Success",
          detail: "Hand Over Letter created and saved in the File System",
        });
      }
    } catch (err) {
      this.log.error(err);
    }
  }

  async createDeliveryReleaseOrder() {
    try {
      const payload = {
        FileId: this.fileId,
      };
      let res = await this.api.createDeliveryReleaseOrder(
        payload,
        this.sendDROEmail
      );
      this.displayDROModal = false;
      this.sendDROEmail = false;
      if (!res) {
        this.messageService.add({
          severity: "error",
          summary: "Error",
          detail: "Failed to Create Delivery Release Order",
        });
      } else {
        this.messageService.add({
          severity: "success",
          summary: "Success",
          detail: "Delivery Release Order created and saved in the File System",
        });
      }
    } catch (error) {
      this.showError(
        "Error Creating Delivery Release Order (createDeliveryReleaseOrder())",
        error
      );
      this.log.error(error);
    }
  }

  private async createLetterOfAuthorityToClearVehicle(fileId) {
    try {
      const payload = {
        FileId: fileId,
      };
      let res = await this.api.createLetterOfAuthorityToClearVehicle(payload);
      if (!res) {
        this.messageService.add({
          severity: "error",
          summary: "Error",
          detail: "Failed to Create Letter Of Authority to Clear Vehicle(s)",
        });
      } else {
        this.messageService.add({
          severity: "success",
          summary: "Success",
          detail:
            "Letter Of Authority to Clear Vehicle(s) created and saved in the File System",
        });
      }
    } catch (error) {
      this.showError(
        "Error Creating Letter Of Authority To Clear Vehicle (createLetterOfAuthorityToClearVehicle())",
        error
      );
      this.log.error(error);
    }
  }

  private async createWindowSheet(fileId) {
    try {
      const userId = this.authService.getLegalEntityId;
      const payload = {
        FileId: fileId,
        UserId: userId,
      };
      let res = await this.api.createWindowSheet(payload);
      if (!res) {
        this.messageService.add({
          severity: "error",
          summary: "Error",
          detail: "Failed to Create Window Sheet",
        });
      } else {
        this.messageService.add({
          severity: "success",
          summary: "Success",
          detail: "Window Sheet created and saved in the File System",
        });
      }
    } catch (error) {
      this.showError(
        "Error Creating Window Sheet (createWindowSheet())",
        error
      );
      this.log.error(error);
    }
  }

  private async generateFileCoverVehicle(fileId) {
    try {
      const userId = this.authService.getLegalEntityId;
      const payload = {
        FileId: fileId,
        UserId: userId,
      };
      let res = await this.api.generateFileCoverVehicle(payload);
      if (!res) {
        this.messageService.add({
          severity: "error",
          summary: "Error",
          detail: "Failed to Generate File Cover Vehicle",
        });
      } else {
        this.messageService.add({
          severity: "success",
          summary: "Success",
          detail: "File Cover Vehicle generated and saved in the File System",
        });
      }
    } catch (err) {
      this.log.error(err);
    }
  }

  private async generateFileCoverContainer(fileId) {
    try {
      const userId = this.authService.getLegalEntityId;
      const payload = {
        FileId: fileId,
        UserId: userId,
      };
      let res = await this.api.generateFileCoverContainer(payload);
      if (!res) {
        this.messageService.add({
          severity: "error",
          summary: "Error",
          detail: "Failed to Generate File Cover Container",
        });
      } else {
        this.messageService.add({
          severity: "success",
          summary: "Success",
          detail: "File Cover Container generated and saved in the File System",
        });
      }
    } catch (err) {
      this.log.error(err);
    }
  }

  private async generateImportersClearingAndForwardingInstructions() {
    try {
      const url = this.config.getServerUrl() + "api/Documents/DownloadImportersClearingInstructions";
      const retval = await this.webApi2.get(null, url);
      if (retval && retval.errorCode == 0) {
        window.open(retval.result);
      } else {
        this.messageService.add({
          severity: "error",
          summary: "Error",
          detail: "Failed To Download Clearing Instructions",
        });
      }
    } catch (e) {
      this.log.error(e);
    }
  }

  public cifLabel() {
    if (this.file.CIF) return "CIF";
    else return "NON-CIF";
  }

  public async checkBalance() {
    try {
      const retval = await this.fileApiService.getOutstandingBalanceForFile(this.file.Id);
      if (!retval) this.toastMessage("error", "Failed to check if file is paid. Please contact support", "");
      else if (retval.errorCode !== 0) this.toastMessage("error", retval.errorMessage, "");
      else this.fileOutStandingAmounts = retval.result;
    } catch (e) {
      this.log.error(e);
    }
  }

  public async CreateManualStorageInvoice() {
    this.actionSpinner = true;
    try {
      if (!this.handleStorageInvoiceDate()) return;

      const dayDifferent: number = moment(this.storageInvoiceDate).startOf('day').diff(moment(Date.now()).startOf('day'), 'days');
      const retval = await this.invoiceApiService.ManualGenerateStorageInvoice(this.fileId, dayDifferent);
      if (!retval) this.toastMessage("error", "Failed to manually generate storage invoice", "");
      else if (retval.errorCode > 0) this.toastMessage("error", retval.errorMessage, "");
      else if (retval.errorCode < 0) this.toastMessage("warn", retval.errorMessage, "");
      else {
        this.toastMessage("success", retval.errorMessage, "");
        this.refreshFinanceTab = true;
        await this.checkBalance();
      }
    } catch (error) {
      this.log.error(error);
      this.toastMessage("error", "Failed to manually generate storage invoice", "");
    }
    this.actionSpinner = false;
  }

  private handleStorageInvoiceDate() {
    const selectedFromDate = moment(this.storageInvoiceDate, 'YYYY/MM/DD');
    const todaysDate = moment();

    if (selectedFromDate.isBefore(todaysDate, 'day')) {
      this.toastMessage('error', 'Cannot charge storage in the past. Please select today or a future date.', '');
      return false;
    }

    return true;
  }

  public onTermsInfoChanged(event: any) {
    this.termsAndconditions = event;
  }

  private async getTermsAndConditions() {
    try {
      const resp = await this.fileApiService.GetTermsAndConditions(this.fileId);
      if (!resp) this.toastMessage('error', 'Failed to get terms and conditions. Please contact support.', '');
      else if (resp.errorCode != 0) this.toastMessage('error', resp.errorMessage, '');
      else this.termsAndconditions = resp.result;
    } catch (error) {
      this.log.error(error);
    }
  }

  public async AcceptTermsAndConditions() {
    try {
      const resp = await this.fileApiService.acceptTerms(this.fileId);
      if (!resp) this.toastMessage('error', 'Failed to accept terms and conditions. Please contact support.', '');
      else if (resp.errorCode != 0) this.toastMessage('error', resp.errorMessage, '');
      else this.toastMessage('success', 'success', resp.errorMessage);
    } catch (error) {
      this.log.error(error);
    }
  }

  public async generateTermsAndConditionsDocument() {
    try {
      const retval = await this.documentService.generateTermsAndConditionsDocument(this.fileId);
      if (!retval) this.toastMessage('error', 'Failed to generate terms and condiftions. Please contact support.', '');
      else if (retval.errorCode != 0) this.toastMessage('error', retval.errorMessage, '');
      else this.toastMessage('success', retval.errorMessage, '');
    } catch (error) {
      this.log.error(error);
    }
  }

  public async websiteLogin() {
    try {
      const URL = await this.api.getWebsiteOTPLink(this.file.ConsigneeLegalEntityId);
      if (URL) {
        window.open(URL, "_blank");
      } else {
        this.messageService.add({
          severity: "error",
          summary: "Error",
          detail: "This Legal Entity does not have a portal login",
        });
      }
    } catch (error) {
      this.log.error(error);
    }
  }

  public async DeclineTermsAndConditions() {
    try {
      const resp = await this.fileApiService.declineTerms(this.fileId);
      if (!resp) this.toastMessage('error', 'Failed to decline terms and conditions. Please contact support.', '');
      else if (resp.errorCode != 0) this.toastMessage('error', resp.errorMessage, '');
      else this.toastMessage('success', 'success', resp.errorMessage);
    } catch (error) {
      this.log.error(error);
    }
  }

  public get clearingStatus(): typeof ClearingStatus { return ClearingStatus; }
  private toastMessage(severity: string, summary: string, detail: string) { this.messageService.add({ severity: severity, summary: summary, detail: detail, life: 10000 }); }
}