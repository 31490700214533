export enum InvoiceType {
  RORO = 1,
  STANDARD = 2,
  CONTAINER = 3,
  PARTIAL_HANDOVER = 4,
  RORO_ADHOC = 5,
  STANDARD_ADHOC = 6,
  CONTAINER_ADHOC = 7,
  PARTIAL_HANDOVER_ADHOC = 8,
  HANDOVER = 9,
  STORAGE = 10
}

export enum ClearingAgent {
  AVECS_CLEARING_AGENT = 1
}

export enum UserTaskType {
  CHECK_SMS = 1,
  CHECK_Email = 2,
  CHECK_Route = 3,
  CRAETE_RORO_QUOTATION = 4,
  ACCEPT_DECLINE_QUOTATION = 5,
  ALLOCATE_PAYMENT = 6,
  REFUND = 7,
  ADD_HANDOVER_AGENT = 8,
  CRAETE_HANDOVER_INVOICE = 9,
  ADD_PARTIAL_HANDOVER_AGENT = 10,
  CHECK_BORDER_POST_FROM_AUTO_RESPONDER = 11,
  WHATSAPP_TALK_TO_AGENT_FOR_A_FILE = 12,
  WHATSAPP_TALK_TO_AGENT_GENERAL = 13,
  UPDATE_CLIENT_SMS_DETAILS = 14,
  UPDATE_CLIENT_EMAIL_DETAILS = 15,
  CHECK_DOCUMENT_UPLOAD = 16,
  ASSIGN_CARGO_TO_TRANSPORTER = 17,
  BORDER_POST_DUTIES = 18,
  ADD_BORDER_AGENT = 19,
  UPDATE_ORIGINAL_DOCS_INFORMATION = 20,
  CREATE_AVECS_INVOICE = 21,
  CREATE_PARTIAL_HANDOVER_INVOICE = 22,
  ADHOC_USER_TASK_FOR_A_FILE = 23,
  CHECK_BORDER_POST_ACCEPT_TERMS_TEMPLATE = 24, // todo in another branch that not deploy re wa template responding
  APPROVE_REJECT_ADMIN_TRANSACTION = 24,
  WHATSAPP_CHECK_PROOF_OF_PAYMENT_DOCUMENT = 25,
  WHATSAPP_CHECK_BORDER_DUTIES_DOCUMENT = 26,
  WHATSAPP_CHECK_PASSPORT_ID_DOCUMENT = 27,
  WHATSAPP_CHECK_OTHER_DOCUMENT = 28,
  FIX_STORAGE_INVOICE_AUTO_GEN = 29,
  UPLOAD_WORKING_DOCS = 30,
  UPLOAD_SAD500 = 31,
  UPLOAD_EDI_RELEASE = 32,
  UPLOAD_CUSTOMS = 33,
  ADHOC_USER_TASK_GENERAL = 34,
  INSPECTION_ROUTE_UPDATE_FAIL = 35, 
  NOTIFY_CLIENT_OF_FILE_CHANGE_DISPATCH_LOAD = 37
}

export enum UserTaskStatus {
  NOT_COMPELTED = 1,
  COMPLETED = 2,
  ARCHIVED = 3,
  CANCELLED = 4
}

export enum ClearingStatus {
  AVECS = 1,
  HANDOVER = 2,
  PARTIAL_HANDOVER = 3
}

export enum Groups {
  ALL = 0,
  ACCOUNTS = 1,
  TRANSPORTER = 3,
  DRIVER = 4,
  CARGO = 7,
  CONSIGNEE = 8,
  PORTAL_ADMIN = 9,
  SYSTEM_ADMIN = 10,
  CLAIMS = 11,
  AGENCY = 17,
  VESSEL_OWNERS = 18,
  SUPPLIER = 19,
  DISPATCH = 20,
  FILE_OWNER = 21,
  BORDER_AGENT = 22,
  HANDOVER = 23,
  FILE_MANAGEMENT = 24,
  WHATSAPP_AGENT = 25,
  ACCOUNTS_ADMIN = 26,
  ADHOC = 27,
  ROUND_ROBIN_ADMIN = 28,
  DISPATCH_ADMIN = 29
}

export enum TransactionType {
  PAYMENT = 2,
  REFUND = 3,
  WRITE_OFF = 4,
  NOTE = 5,
  INVOICE_ITEM = 6,
  INVOICE_ITEM_UPDATE = 7,
  INVOICE_ITEM_CANCELLED = 8,
  DISCOUNT = 9,
  INVOICE_CANCELLED_DISCOUNT = 10,
  INVOICE_UPDATE_DISCOUNT = 11,
  ADMIN_NEGATIVE = 12,
  ADMIN_POSITIVE = 13
}

export enum LegalEntityType {
  PERSON = 1,
  COMPANY = 2
}

export enum LegalEntityRolesTypes {
  SUPPLIER = 1,
  CLIENT = 2,
  PROSPECT = 3,
  COURIER = 4,
  TRANSPORT_MANAGER = 5,
  TRANSPORT_DRIVER = 6,
  MANAGER = 7,
  TRANSPORT_PLANNER = 8,
  CLERK = 9,
  DISPATCH_MANAGER = 10,
  FINANCIAL_MANAGER = 11,
  ACCOUNTANT = 12,
  BOOKKEEPER = 13,
  DIRECTOR = 14,
  CLIENT_LIASON_MGR = 15
}

export enum DocumentStatusType {
  VERIFIED = 1,
  UNVERIFIED = 2,
  CANCELLED = 3,
  EXPIRED = 4,
  DECLINED = 5
}

export enum Type {
  TEXT = 1,
  BOOL = 2,
  DATE = 3,
  NUMBER = 4,
  DBN = 5,
  BUTTON = 6
}

export enum LocationTypes {
  PORT = 1,
  BORDER = 2,
  PLACE = 3,
  STORAGE_POINT = 4,
  ADDRESS = 5,
  COUNTRY = 6
}

export enum WAProducts {
  NOVA_DT_OLD = 1,
  SAEX = 7,
  NOVA_DT = 8
}

export enum FileDocumentSource {
  FACSIMILE = 1,
  ORIGINAL_DOCUMENTS = 2,
  EMAIL = 3,
  TELEX = 4
}

export enum NoteType {
  OTHER = 1,
  BORDER_DUTIES_RECEIVED = 2,
  CLEARANCE_AND_DELIVERY_PAID_IN_FULL = 3,
  FUEL_SURCHARGE_PAID_IN_FULL = 4,
  BORDER_DUTIES_SENT = 5,
  BORDER_DUTIES_CONFIRMED = 6,
  STORAGE_PAID_IN_FULL = 7,
  CARGO_DISPATCHED = 8,
  ROUTE_CHANGE = 9,
  PROCESS_INSTANCE_DELETED = 10,
  FILE_CONVERTED_TO_HANDOVER = 11,
  FILE_CONVERTED_TO_PARTIAL_HANDOVER = 12,
  BORDER_DUTIES_UPLOADED = 13,
  FILE_CONVERTED_TO_RORO = 14,
  FILE_REOPENED = 15,
  FILE_ROUTE_RESET = 16,
  DISPATCH_NOTE = 17,
  ADHOC_USER_TASK_DESCRIPTION = 18
}

export enum DocumentCategory {
  ANF = 1,
  AUTHORISATION_LETTER = 2,
  ACQUITTALS = 3,
  CUSTOMS = 4,
  DELIVERY = 5,
  VEHICLE_INSPECTION = 6,
  INVOICES = 7,
  PASSPORT_IDENTITY_DOCUMENT = 8,
  PAYMENTS = 9,
  PORTNET = 10,
  SCANS = 11,
  T896 = 12,
  WORKING_DOCS = 13,
  INSPECTION_IMAGES = 14,
  INSPECTION_DAMAGES = 15,
  BORDER_REPORT = 16,
  CN2_DOCUMENTS = 17,
  CN1_DOCUMENTS = 18,
  SUPPLIERS_INVOICE = 19,
  BILL_OF_LADING = 20,
  INSPECTION_DAMAGE_LOCATION = 21,
  TSHIRT_DELIVERY = 22,
  INSPECTION_IMAGE_REPORT = 23,
  PORTNET_BOOKING_SHEET = 24,
  UPDATED_BOOKING_SHEET = 25,
  STATEMENTS = 26,
  CLAIMS = 27,
  CLAIM_IMAGES = 28,
  TERMS_AND_CONDITIONS = 29,
  MASTERBILL = 30,
  CARGO_DUES = 31,
  LANDING_ORDER = 32,
  ORIGINAL_DOCUMENTS = 33,
  LICENSE = 36,
  HBL = 38,
  ARRIVAL_NOTICE = 39,
  HANDOVER_LETTER = 40,
  DELIVERY_RELEASE_ORDER = 41,
  LETTER_OF_AUTHORITY = 42,
  WINDOW_SHEET = 43,
  BORDER_POST_DUTIES = 50,
  PHInvoice = 51,
  QUOTES = 52,
  PH_QUOTES = 53,
  HANDOVER_INVOICE = 54,
  PROOF_OF_PAYMENT = 55,
  OTHER = 56,
  VEHICLE_FILE_COVER = 1040,
  CONTAINER_FILE_COVER = 1041,
  SAD500 = 1044,
  EDIRELEASE = 1045,
  CNI = 1046,
  CLEARING_AND_FORWARDING = 1048,
  EXPORT_CERTIFICATE = 1050,
  LETTER_OF_AUTHORITY_TEMPLATE = 1051,
  STORAGE_INVOICE = 1052
}

export enum OnDemandType {
  DEFAULT_EXCEL = 1,
  EXAMPLE_CUSTOM_EXCEL = 2
}

export enum CriteriaType {
  DATEPICKER = 1,
  INPUTTEXT = 2,
  INPUTNUMBER = 3,
  DROPDOWN = 4,
  AUTOCOMPLETE = 5
}

export enum FileIssueType {
  SUCCESS = 1,
  ERROR = 2,
  WARNING = 3,
  INFO = 4
}

export enum OnDemandTemplate {
  ALL_FILES_WITH_NOTES_AND_DUTIES_AND_INSPECTED = 1,
  GETDOCUMENT_BY_DOCCATEGORY_UPLOADEDBY_FOR_DATERANGE_REPORT = 2,
  DUTIES_PAID = 3,
  GETDOCUMENT_BY_DOCCATEGORY_NOT_PRESENT_FOR_FILE_BY_DATE_REPORT = 5,
  MONTHEND_CIF = 6,
  MONTHEND_INVOICE_UNPAID = 7,
  MONTHEND_INVOICE_ALL = 8,
  SEND_DOCUMENTS_OF_CATEGORY_FOR_VOYAGE_TO_CONSIGNEE = 9,
  HAS_IT_ARRIVED_AND_BEEN_INSPECTED_AND_T896_AND_DUTIES_IF_APPLICABLE = 10,
  VESSEL_LIST = 13,
  DUTIES_PAID_AND_ACCEPTED_OR_NOT = 16,
  EMAIL_REPORT = 17,
  BFJ_TRACKING_SHEET_FOR_DELIVERY = 18,
  BFJ_TRACKING_SHEET_FOR_STORAGE = 19,
  STOCK_TAKE_FOR_WAREHOUSE = 23,
  USER_TASK_HISTORY = 25,
  FILES_NOT_READY_FOR_DELIVERY_PLANNING = 27,
  FILE_OWNERS_AND_FILES = 28,
  FILES_READY_FOR_DELIVERY_PLANNING = 29,
  VIEW_COMPLETED_DELIVERY_LOAD_PLANS = 30,
  TRACKING_SHEET_STORAGE_FOR_NON_BFJ = 31,
  TRACKING_SHEET_DELIVERY_FOR_NON_BFJ = 32,
  WAREHOUSE_STORAGE_REPORT = 33,
  SEND_DOCUMENTS_OF_CATEGORY_FOR_VOYAGE_TO_WAREHOUSE = 35
}

export enum TransactionIntentStatus {
  NOT_APPROVED_OR_DECLINED = 1,
  APPROVED = 2,
  REJECTED = 3,
  ARCHIVED = 4
}

export enum Currency {
  PULA = 1,
  RAND = 2,
  US_DOLLAR = 3,
  ZIMBABWEAN_DOLLAR = 4
}

export enum AssignToType {
  INDIVIDUAL = 1,
  GROUP = 2
}

export enum GenericType {
  DOCUMENTS = 1,
  INSPECTION = 2,
  EMAIL = 3,
  SMS = 4,
  FILE = 5,
  INVOICE = 6,
  INVOICE_ITEM = 7,
  PAYMENT = 8,
  REFUND = 9,
  WRITE_OFF = 10,
  LEDGER_NOTE = 11,
  QUOTE = 12,
  LOCATION = 13,
  WHATSAPP_CHATS = 14,
  VOYAGE = 15,
  TRANSACTION_INTENTS = 16,
  INVOICE_DOCUMENT = 101
}

export enum BorderPostStatus {
  DUTIES_NOT_REQUIRED = 0,
  DUTIES_REQUIRED = 1,
  DUTIES_REQUIRED_AND_DUTIES_CONFIRMED = 2
}

export enum LoadPlanStatus {
  IN_PLANNING = 1,
  DISCARDED = 2,
  ASSIGNED_TO_TRANSPORTER = 3,
  ACCEPTED_BY_TRANSPORTER = 4,
  DECLINED_BY_TRANSPORTER = 5,
  UNASSIGN = 6,
  IN_TRANSIT = 7,
  OFFLOADED = 8
}

export enum FileEventStatus {
  INACTIVE = 1,
  ACTIVE = 2
}

export enum FileEventType {
  FILE_OPENED = 1,
  BORDER_DUTIES_SENT = 2,
  BORDER_DUTIES_PAID = 3,
  BORDER_DUTIES_UNPAID = 4,
  BORDER_DUTIES_PART_PAID = 5,
  BORDER_DUTIES_OTHER = 6,
  INVOICE_SENT = 7,
  INVOICE_PAID = 8,
  FILE_CONVERTED = 9,
  INSPECTED = 10,
  OTHER = 11,
  WHATSAPP_BORDER_POST_CONFIRMATION = 12,
  WHATSAPP_BORDER_POST_DECLINE = 13,
  WHATSAPP_USER_REQUESTED_TO_TALK_TO_AN_AGENT_FOR_A_FILE = 14,
  WHATSAPP_USER_REQUESTED_TO_TALK_TO_AN_AGENT_GENERAL = 15,
  FLOOD_DAMAGE_FEB = 16,
  CLEARANCE_AND_DELIVERY_REMINDER_SENT = 17,
  DUTY_REMINDER_SENT = 18,
  WEEKLY_STATEMENT_SENT = 19,
  FIRST_ARREAR_NOTICE_SENT = 20,
  SECOND_ARREAR_NOTICE_SENT = 21,
  LETTER_OF_DEMAND_SENT = 22,
  FINAL_NOTICE_SENT = 23,
  ADMIN_TRANSACTION_RAISED = 24,
  ADMIN_TRANSACTION_REJECTED = 25,
  ADMIN_TRANSACTION_APPROVED = 26,
  VEHICLE_SOLD = 27,
  VEHICLE_STOLEN = 28,
  VEHICLE_REPLACED = 29,
  NO_COMMUNICATIONS = 30,
  NO_PAYMENT_REMINDERS = 31,
  NO_ARREAR_NOTICES = 32,
  NO_NON_CIF_PAYMENT_REMINDERS = 33,
  NO_DUTIES_REMINDERS = 34,
  NO_STORAGE = 35
}

export enum FilePreferenceType {
  ALL_COMMUNICATIONS = 1,
  PAYMENT_REMINDERS = 2,
  ARREAR_NOTICES = 3,
  AUTOGENERATE_STORAGE = 4
}

export enum GeneralStatus {
  ACTIVE = 1,
  INACTIVE = 2
}