import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ConfigService } from './config.service';
import { LogBase } from './logger.service';
import { WebApi2Service } from './web.api2.service';
import "rxjs/add/operator/toPromise";
import * as _ from "lodash";
import { AuthenticationService } from './api/auth/authentication.service';
import { DispatchCollecionLoads } from '../interfaces/global.interfaces';

@Injectable({
  providedIn: 'root'
})
export class Api2Service {
  user: string;

  constructor(
    private config: ConfigService,
    private router: Router,
    private log: LogBase,
    private webApi: WebApi2Service,
    private authService: AuthenticationService
  ) { }

  async ngOnInit() { this.user = this.authService.getUsername; }

  // Endpoint: api/transport/getWarehouses
  // Controller Name: TransportController.cs
  // Proc Name: pr_GetWarehouses
  async getWarehouses() {
    try {
      var url = this.config.getServerUrl() + "api/transport/getWarehouses";
      var response = await this.webApi.post(this.user, url, null);
      if (response.errorCode == 0) {
        return response.result;
      } else if (response.errorCode == -2) {
        this.router.navigate(["/login"]);
      } else {
        return null;
      }
    } catch (error) {
      this.log.error(error);
    }
    return null;
  }

  async getActiveTransporters() {
    try {
      var url = this.config.getServerUrl() + "api/transport/getActiveTransporters";
      var response = await this.webApi.post(this.user, url, null);
      if (response.errorCode == 0) {
        return response.result;
      } else if (response.errorCode == -2) {
        this.router.navigate(["/login"]);
      } else {
        return null;
      }
    } catch (error) {
      this.log.error(error);
    }
    return null;
  }


  async getFilteredRoutes(PortOfDeparture: any, PortOfArrival: any) {
    try {
      var url = this.config.getServerUrl() + "api/route/getFilteredRoutes?PortOfDeparture=" + PortOfDeparture + "&PortOfArrival=" + PortOfArrival;
      var response = await this.webApi.post(this.user, url, null);
      if (response.errorCode == 0) {
        return response.result;
      } else if (response.errorCode == -2) {
        this.router.navigate(["/login"]);
      } else {
        return null;
      }
    } catch (error) {
      this.log.error(error);
    }
    return null;
  }

  async getMonthlyCarMovementStats(StartDate, EndDate) {
    try {
      var url = this.config.getServerUrl() + "api/summaryDashboard/GetMonthlyCarMovementsStats?StartDate=" + StartDate + "&EndDate=" + EndDate;
      var response = await this.webApi.get(this.user, url);
      if (response.errorCode == 0) {
        return response.result;
      } else if (response.errorCode == -2) {
        this.router.navigate(["/login"]);
      } else {
        return null;
      }
    } catch (error) {
      this.log.error(error);
    }
    return null;
  }

  async getCurrentNumberVehiclePerVoyage() {
    try {
      var url = this.config.getServerUrl() + "api/summaryDashboard/rptNoOfVehiclesPerVesselVoyage";
      var response = await this.webApi.get(this.user, url);
      if (response.errorCode == 0) {
        return response.result;
      } else if (response.errorCode == -2) {
        this.router.navigate(["/login"]);
      } else {
        return null;
      }
    } catch (error) {
      this.log.error(error);
    }
    return null;
  }

  async getCurrentNumberVehiclePerVoyageDateRange(StartDate, EndDate) {
    try {
      var url = this.config.getServerUrl() + "api/summaryDashboard/rtpNoOfCarsPerVesselDates?BeginDate=" + StartDate + "&EndDate=" + EndDate;
      var response = await this.webApi.get(this.user, url);
      if (response.errorCode == 0) {
        return response.result;
      } else if (response.errorCode == -2) {
        this.router.navigate(["/login"]);
      } else {
        return null;
      }
    } catch (error) {
      this.log.error(error);
    }
    return null;
  }

  async getCurrentVesselArrivalVisual() {
    try {
      var url = this.config.getServerUrl() + "api/summaryDashboard/GetShipArrivalVisualCurrentMonth";
      var response = await this.webApi.get(this.user, url);
      if (response.errorCode == 0) {
        return response.result;
      } else if (response.errorCode == -2) {
        this.router.navigate(["/login"]);
      } else {
        return null;
      }
    } catch (error) {
      this.log.error(error);
    }
    return null;
  }

  async getVesselArrivalVisual(BeginDate, EndDate?) {
    try {
      var url = this.config.getServerUrl() + "api/summaryDashboard/GetShipArrivalVisual?BeginDate=" + BeginDate + "&EndDate=" + EndDate;
      var response = await this.webApi.get(this.user, url);
      if (response.errorCode == 0) {
        return response.result;
      } else if (response.errorCode == -2) {
        this.router.navigate(["/login"]);
      } else {
        return null;
      }
    } catch (error) {
      this.log.error(error);
    }
    return null;
  }

  async getVesselCalendarNotes() {
    try {
      var url = this.config.getServerUrl() + "api/summaryDashboard/getVesselCalendarNotes";
      var response = await this.webApi.get(this.user, url);
      if (response.errorCode == 0) {
        return response.result;
      } else if (response.errorCode == -2) {
        this.router.navigate(["/login"]);
      } else {
        return null;
      }
    } catch (error) {
      this.log.error(error);
    }
    return null;
  }

  async getBackBoardData() {
    try {
      var url = this.config.getServerUrl() + "api/summaryDashboard/SummaryDashboardBackBoardTable";
      var response = await this.webApi.get(this.user, url);
      if (response.errorCode == 0) {
        return response.result;
      } else if (response.errorCode == -2) {
        this.router.navigate(["/login"]);
      } else {
        return null;
      }
    } catch (error) {
      this.log.error(error);
    }
    return null;
  }

  async GetBackBoardDataWithMonth(monthDate) {
    try {
      var url = this.config.getServerUrl() + "api/summaryDashboard/SummaryDashboardBackBoardTableDateSelect?Month=" + monthDate;
      var response = await this.webApi.get(this.user, url);
      if (response.errorCode == 0) {
        return response.result;
      } else if (response.errorCode == -2) {
        this.router.navigate(["/login"]);
      } else {
        return null;
      }
    } catch (error) {
      this.log.error(error);
    }
    return null;
  }

  async GetListOfVesselsForNotes() {
    try {
      var url = this.config.getServerUrl() + "api/summaryDashboard/getListOfVesselsForNotes";
      var response = await this.webApi.get(this.user, url);
      if (response.errorCode == 0) {
        return response.result;
      } else if (response.errorCode == -2) {
        this.router.navigate(["/login"]);
      } else {
        return null;
      }
    } catch (error) {
      this.log.error(error);
    }
    return null;
  }

  async GetVoyageNumbersforNotes() {
    try {
      var url = this.config.getServerUrl() + "api/summaryDashboard/getVoyageNumbersforNotes";
      var response = await this.webApi.get(this.user, url);
      if (response.errorCode == 0) {
        return response.result;
      } else if (response.errorCode == -2) {
        this.router.navigate(["/login"]);
      } else {
        return null;
      }
    } catch (error) {
      this.log.error(error);
    }
    return null;
  }

  async updateVesselNotes(payload) {
    try {
      const url = this.config.getServerUrl() + "api/summaryDashboard/updateVesselNotes";
      const retval = await this.webApi.post(this.user, url, JSON.stringify(payload));
      if (retval.errorCode === 0) {
        return true;
      } else if (retval.errorCode === -2) {
        this.router.navigate(["/login"]);
      } else {
        return undefined;
      }
    } catch (error) {
      this.log.error(error);
    }
  }

  async SetVesselNotesToInactive(noteId) {
    try {
      const url = this.config.getServerUrl() + "api/summaryDashboard/SetVesselNotesToInactive?Id=" + noteId;
      const retval = await this.webApi.post(this.user, url, JSON.stringify(noteId));
      if (retval.errorCode === 0) {
        return true;
      } else if (retval.errorCode === -2) {
        this.router.navigate(["/login"]);
      } else {
        return undefined;
      }
    } catch (error) {
      this.log.error(error);
    }
  }

  async getOutstandingUserTasksDashboard() {
    try {
      var url = this.config.getServerUrl() + "api/summaryDashboard/UserTasksDashboard";
      var response = await this.webApi.get(this.user, url);
      if (response.errorCode == 0) {
        return response.result;
      } else if (response.errorCode == -2) {
        this.router.navigate(["/login"]);
      } else {
        return null;
      }
    } catch (error) {
      this.log.error(error);
    }
    return null;
  }

  async SummaryDashboardCargoTracker() {
    try {
      var url = this.config.getServerUrl() + "api/summaryDashboard/CargoTracker";
      var response = await this.webApi.get(this.user, url);
      if (response.errorCode == 0) {
        return response.result;
      } else if (response.errorCode == -2) {
        this.router.navigate(["/login"]);
      } else {
        return null;
      }
    } catch (error) {
      this.log.error(error);
    }
    return null;
  }

  async GetFilesAtCurrentLocation(LocationId: number, VoyageId: number) {
    try {
      var url = this.config.getServerUrl() + "api/summaryDashboard/CargoTrackerGetFilesAtLocation?LocationId=" + LocationId + "&VoyageId=" + VoyageId;
      var response = await this.webApi.get(this.user, url);
      if (response.errorCode == 0) {
        return response.result;
      } else if (response.errorCode == -2) {
        this.router.navigate(["/login"]);
      } else {
        return null;
      }
    } catch (error) {
      this.log.error(error);
    }
    return null;
  }

  async getFilesNotDelivered() {
    try {
      var url = this.config.getServerUrl() + "api/summaryDashboard/GetFilesUndeliveredToFinalDestinationWithDurbanInRoute";
      var response = await this.webApi.get(this.user, url);
      if (response.errorCode == 0) {
        return response.result;
      } else if (response.errorCode == -2) {
        this.router.navigate(["/login"]);
      } else {
        return null;
      }
    } catch (error) {
      this.log.error(error);
    }
    return null;
  }

  async GetFileMasterRoutes() {
    try {
      var url = this.config.getServerUrl() + "api/route/GetFileMasterRoutes";
      var response = await this.webApi.get(this.user, url);
      if (response.errorCode == 0) {
        return response.result;
      } else if (response.errorCode == -2) {
        this.router.navigate(["/login"]);
      } else {
        return null;
      }
    } catch (error) {
      this.log.error(error);
    }
    return null;
  }

  async GetFileMasterRouteLegs(masterRouteId: number) {
    try {
      var url = this.config.getServerUrl() + "api/route/GetFileMasterRouteLegs?masterRouteId=" + masterRouteId;
      var response = await this.webApi.get(this.user, url);
      if (response.errorCode == 0) {
        return response.result;
      } else if (response.errorCode == -2) {
        this.router.navigate(["/login"]);
      } else {
        return null;
      }
    } catch (error) {
      this.log.error(error);
    }
    return null;
  }

  async getMatchingRouteLegsFromMasterRouteIds(masterRouteIds: number[]) {
    try {
      let payload: any = {
        masterRouteIds: masterRouteIds
      };
      var url = this.config.getServerUrl() + "api/route/GetMatchingRouteLegsFromMasterRouteIds";
      var response = await this.webApi.post(this.user, url, payload);
      if (response.errorCode == 0) {
        return response.result;
      } else if (response.errorCode == -2) {
        this.router.navigate(["/login"]);
      } else {
        return null;
      }
    } catch (error) {
      this.log.error(error);
    }
    return null;
  }

  async updateFilesRouteStatus(payload: any) {
    try {
      var url = this.config.getServerUrl() + "api/route/UpdateFileIdsRouteStatus";
      var response = await this.webApi.post(this.user, url, payload);
      if (response.errorCode == -2) {
        this.router.navigate(["/login"]);
      }
      return response;
    } catch (error) {
      this.log.error(error);
    }
    return null;
  }

  async ModifyRouteLegs(payload: any) {
    try {
      const url = this.config.getServerUrl() + "api/route/ModifyRouteLegs";
      const retval = await this.webApi.post(this.user, url, payload);
      if (retval.errorCode === 0) {
        return retval;
      } else if (retval.errorCode === -2) {
        this.router.navigate(["/login"]);
      } else {
        return undefined;
      }
    } catch (error) {
      this.log.error(error);
    }
  }

  async checkIfFilesAreUsingMasterRoute(parentRouteId: number) {
    try {
      const url = this.config.getServerUrl() + "api/route/CheckIfFilesAreUsingMasterRoute?ParentRouteId=" + parentRouteId;
      const retval = await this.webApi.get(this.user, url);
      if (retval.errorCode === -2) {
        this.router.navigate(["/login"]);
      }
      else {
        return retval;
      }
    } catch (error) {
      this.log.error(error);
    }
  }

  async setMasterRouteActiveState(payload: any) {
    try {
      const url = this.config.getServerUrl() + "api/route/SetMasterRouteActiveState";
      const retval = await this.webApi.post(this.user, url, payload);
      if (retval.errorCode === 0) {
        return retval;
      } else if (retval.errorCode === -2) {
        this.router.navigate(["/login"]);
      } else {
        return undefined;
      }
    } catch (error) {
      this.log.error(error);
    }
  }

  async createMasterRoute(payload: any) {
    try {
      const url = this.config.getServerUrl() + "api/route/CreateMasterRoute";
      const retval = await this.webApi.post(this.user, url, payload);
      if (retval.errorCode === 0) {
        return retval;
      } else if (retval.errorCode === -2) {
        this.router.navigate(["/login"]);
      } else {
        return undefined;
      }
    } catch (error) {
      this.log.error(error);
    }
  }

  async getMasterRouteAndRouteLegs(parentRouteId: number) {
    try {
      const url = this.config.getServerUrl() + "api/route/GetMasterRouteAndRouteLegs?ParentRouteId=" + parentRouteId;
      const retval = await this.webApi.post(this.user, url, null);
      if (retval.errorCode === 0) {
        return retval.result;
      } else if (retval.errorCode === -2) {
        this.router.navigate(["/login"]);
      } else {
        return undefined;
      }
    } catch (error) {
      this.log.error(error);
    }
  }

  async getAllRoutes() {
    try {
      const url = this.config.getServerUrl() + "api/route/GetAllRoutes";
      const retval = await this.webApi.get(this.user, url);
      if (retval.errorCode === 0) {
        return retval.result;
      } else if (retval.errorCode === -2) {
        this.router.navigate(["/login"]);
      } else {
        return undefined;
      }
    } catch (error) {
      this.log.error(error);
    }
  }

  async checkIfRouteAlreadyExists(routeDescription: string) {
    try {
      const url = this.config.getServerUrl() + "api/route/CheckIfRouteAlreadyExists?RouteDescription=" + routeDescription;
      const retval = await this.webApi.get(this.user, url);
      if (retval) {
        if (retval.errorCode === -2) {
          this.router.navigate(["/login"]);
        }
        else {
          return retval;
        }
      }
      else {
        return undefined;
      }
    } catch (error) {
      this.log.error(error);
    }
  }

  async getRouteTypes() {
    try {
      const url = this.config.getServerUrl() + "api/route/GetRouteTypes";
      const retval = await this.webApi.get(this.user, url);
      if (retval.errorCode === 0) {
        return retval.result;
      } else if (retval.errorCode === -2) {
        this.router.navigate(["/login"]);
      } else {
        return undefined;
      }
    } catch (error) {
      this.log.error(error);
    }
  }

  async getLocationTypes() {
    try {
      const url = this.config.getServerUrl() + "api/route/GetLocationTypes";
      const retval = await this.webApi.get(this.user, url);
      if (retval.errorCode === 0) {
        return retval.result;
      } else if (retval.errorCode === -2) {
        this.router.navigate(["/login"]);
      } else {
        return undefined;
      }
    } catch (error) {
      this.log.error(error);
    }
  }

  async getDispatchPlannerLocationTypes() {
    try {
      const url = this.config.getServerUrl() + "api/route/GetLocationTypes";
      const retval = await this.webApi.get(this.user, url);
      if (retval.errorCode === 0) {
        return retval.result;
      } else if (retval.errorCode === -2) {
        this.router.navigate(["/login"]);
      } else {
        return undefined;
      }
    } catch (error) {
      this.log.error(error);
    }
  }

  async GetLocationFromLocationId(locType: number) {
    try {
      const url = this.config.getServerUrl() + "api/locations/GetLocationsByLocationTypeId?LocationTypeId=" + locType;
      const retval = await this.webApi.post(this.user, url, null);
      if (retval.errorCode === 0) {
        return retval.result;
      } else if (retval.errorCode === -2) {
        this.router.navigate(["/login"]);
      } else {
        return undefined;
      }
    } catch (error) {
      this.log.error(error);
    }
  }

  async CreateNewPrePlanLoad(payload) {
    try {
      const url = this.config.getServerUrl() + "api/delivery/AddNewDispatchPrePlanLoad";
      const retval = await this.webApi.post(this.user, url, payload);
      if (retval.errorCode === 0) {
        return retval.result;
      } else if (retval.errorCode === -2) {
        this.router.navigate(["/login"]);
      } else {
        return undefined;
      }
    } catch (error) {
      this.log.error(error);
    }
  }

  async CreateNewCollectionLoad(payload) {
    try {
      const url = this.config.getServerUrl() + "api/collection/AddNewDispatchLoadPlan";
      const retval = await this.webApi.post(this.user, url, payload);
      if (retval.errorCode === 0) {
        return retval.result;
      } else if (retval.errorCode === -2) {
        this.router.navigate(["/login"]);
      } else {
        return undefined;
      }
    } catch (error) {
      this.log.error(error);
    }
  }

  async EditCollectionDispatchLoadInformation(payload) {
    try {
      const url = this.config.getServerUrl() + "api/collection/EditDispatchLoadInformation";
      const retval = await this.webApi.post(this.user, url, payload);
      if (retval.errorCode === 0) {
        return retval.result;
      } else if (retval.errorCode === -2) {
        this.router.navigate(["/login"]);
      } else {
        return undefined;
      }
    } catch (error) {
      this.log.error(error);
      console.log(error);
    }
  }
  async EditDeliveryDispatchLoadInformation(payload) {
    try {
      const url = this.config.getServerUrl() + "api/delivery/EditDispatchLoadInformation";
      const retval = await this.webApi.post(this.user, url, payload);
      if (retval.errorCode === 0) {
        return retval.result;
      } else if (retval.errorCode === -2) {
        this.router.navigate(["/login"]);
      } else {
        return undefined;
      }
    } catch (error) {
      this.log.error(error);
      console.log(error);
    }
  }

  async EditCollectionCargoAssignmentStatus(CargoLoadAssignmentId: number, StatusId: number) {
    try {
      const url = this.config.getServerUrl() + "api/collection/UpdateCargoLoadAssignmentStatus?CargoLoadAssignmentId=" + CargoLoadAssignmentId
        + '&StatusId=' + StatusId;
      return await this.webApi.post(this.user, url, null);
    } catch (error) {
      this.log.error(error);
      console.log(error);
    }
  }

  async EditDeliveryCargoAssignmentStatus(CargoLoadAssignmentId: number, StatusId: number) {
    try {
      const url = this.config.getServerUrl() + "api/delivery/UpdateCargoLoadAssignmentStatus?CargoLoadAssignmentId=" + CargoLoadAssignmentId
        + '&StatusId=' + StatusId;
      const retval = await this.webApi.post(this.user, url, null);
      if (retval && retval.errorCode === -2) {
        this.router.navigate(["/login"]);
      } else {
        return retval;
      }
    } catch (error) {
      this.log.error(error);
      console.log(error);
    }
  }

  async GetDeliveryLoadPlanCargoItemsByLoadId(loadId: number) {
    try {
      const url = this.config.getServerUrl() + "api/delivery/GetLoadPlanCargoItemsByLoadId?loadId=" + loadId;
      const retval = await this.webApi.get(this.user, url);
      if (retval.errorCode === 0) {
        return retval.result;
      } else if (retval.errorCode === -2) {
        this.router.navigate(["/login"]);
      } else {
        return undefined;
      }
    } catch (error) {
      this.log.error(error);
    }
  }


  async GetPrePlanCargoItemsByLoadId(loadId: number) {
    try {
      const url = this.config.getServerUrl() + "api/delivery/GetPrePlanCargoItemsByLoadId?loadId=" + loadId;
      const retval = await this.webApi.get(this.user, url);
      if (retval.errorCode === 0) {
        return retval.result;
      } else if (retval.errorCode === -2) {
        this.router.navigate(["/login"]);
      } else {
        return undefined;
      }
    } catch (error) {
      this.log.error(error);
    }
  }

  async PromotePrePlanLoadToDeliveryLoad(loadId: number) {
    try {
      const url = this.config.getServerUrl() + "api/delivery/PromotePrePlanLoadToDeliveryLoad?loadId=" + loadId;
      const retval = await this.webApi.post(this.user, url, null);
      if (retval.errorCode === 0) {
        return retval.result;
      } else if (retval.errorCode === -2) {
        this.router.navigate(["/login"]);
      } else {
        return undefined;
      }
    } catch (error) {
      this.log.error(error);
    }
  }

  async GetCollectionLoadPlanCargoItemsByLoadId(loadId: number) {
    try {
      const url = this.config.getServerUrl() + "api/collection/GetLoadPlanCargoItemsByLoadId?loadId=" + loadId;
      const retval = await this.webApi.get(this.user, url);
      if (retval.errorCode === 0) {
        return retval.result;
      } else if (retval.errorCode === -2) {
        this.router.navigate(["/login"]);
      } else {
        return undefined;
      }
    } catch (error) {
      this.log.error(error);
    }
  }
  async RemoveCollectionLoadCargoItem(payload) {
    try {
      const url = this.config.getServerUrl() + "api/collection/RemoveCargoRowFromLoadPlan";
      const retval = await this.webApi.post(this.user, url, payload);
      if (retval.errorCode === 0) {
        return retval.result;
      } else if (retval.errorCode === -2) {
        this.router.navigate(["/login"]);
      } else {
        return undefined;
      }
    } catch (error) {
      this.log.error(error);
    }
  }

  async RemoveDeliveryLoadCargoItem(payload) {
    try {
      const url = this.config.getServerUrl() + "api/delivery/RemoveCargoDeliveryPlan";
      const retval = await this.webApi.post(this.user, url, payload);
      if (retval.errorCode === 0) {
        return retval.result;
      } else if (retval.errorCode === -2) {
        this.router.navigate(["/login"]);
      } else {
        return undefined;
      }
    } catch (error) {
      this.log.error(error);
    }
  }
  async RemoveDeliveryPrePlanCargoItem(payload) {
    try {
      const url = this.config.getServerUrl() + "api/delivery/RemoveCargoRowFromPrePlan";
      const retval = await this.webApi.post(this.user, url, payload);
      if (retval.errorCode === 0) {
        return retval.result;
      } else if (retval.errorCode === -2) {
        this.router.navigate(["/login"]);
      } else {
        return undefined;
      }
    } catch (error) {
      this.log.error(error);
    }
  }

  async GetDeliveryLoads() {
    try {
      const url = this.config.getServerUrl() + "api/delivery/getDispatchLoadPlans";
      const retval = await this.webApi.get(this.user, url);
      if (retval.errorCode === 0) {
        return retval.result;
      } else if (retval.errorCode === -2) {
        this.router.navigate(["/login"]);
      } else {
        return undefined;
      }
    } catch (error) {
      this.log.error(error);
    }
  }

  async GetDispatchPlanStatusForAssignment() {
    try {
      const url = this.config.getServerUrl() + "api/collection/GetDispatchPlanTypeForAssignment";
      const retval = await this.webApi.get(this.user, url);
      if (retval.errorCode === 0) {
        return retval.result;
      } else if (retval.errorCode === -2) {
        this.router.navigate(["/login"]);
      } else {
        return undefined;
      }
    } catch (error) {
      this.log.error(error);
    }
  }

  async GetCollectionLoads(): Promise<DispatchCollecionLoads[]> {
    try {
      const url = this.config.getServerUrl() + "api/collection/getDispatchLoadPlans";
      const retval = await this.webApi.get(this.user, url);
      if (retval.errorCode === 0) {
        return retval.result;
      } else if (retval.errorCode === -2) {
        this.router.navigate(["/login"]);
      } else {
        return undefined;
      }
    } catch (error) {
      this.log.error(error);
    }
  }

  async FilesReadyForBorderpostsPrePlanning(borderPostId: number) {
    try {
      var url = this.config.getServerUrl() + "api/delivery/FilesReadyForBorderpostsPrePlanning?borderpostId=" + borderPostId;
      var response = await this.webApi.post(this.user, url, null);
      if (response.errorCode == 0) {
        return response.result;
      } else if (response.errorCode == -2) {
        this.router.navigate(["/login"]);
      } else {
        return null;
      }
    } catch (error) {
      this.log.error(error);
    }
    return null;
  }

  async GetLoadsForFilteredBorderPosts(locationId: number) {
    try {
      var url = this.config.getServerUrl() + "api/delivery/GetLoadsForFilteredBorderposts?locationId=" + locationId;;
      var response = await this.webApi.post(this.user, url, null);
      if (response.errorCode == 0) {
        return response.result;
      } else if (response.errorCode == -2) {
        this.router.navigate(["/login"]);
      } else {
        return null;
      }
    } catch (error) {
      this.log.error(error);
    }
    return null;
  }
  async getLoadsReadyForFilesToDispatch() {
    try {
      var url = this.config.getServerUrl() + "api/delivery/GetLoadPlansForFilesReadyForDispatch";
      var response = await this.webApi.post(this.user, url, null);
      if (response.errorCode == 0) {
        return response.result;
      } else {
        return null;
      }
    } catch (error) {
      this.log.error(error);
    }
    return null;
  }


  async GetFileCargoNotSerialised(fileId) {
    try {
      const url = this.config.getServerUrl() + "api/Files/GetFileCargoNew?FileId=" + fileId;
      const retval = await this.webApi.post(this.user, url, null);
      if (retval.errorCode === 0) {
        return retval.result;
      } else if (retval.errorCode === -2) {
        this.router.navigate(["/login"]);
      } else {
        return undefined;
      }
    } catch (error) {
      this.log.error(error);
    }
  }

  async AddToDeliveryPrePlan(payload) {
    try {
      const url = this.config.getServerUrl() + "api/delivery/AddToDeliveryPrePlan";
      const retval = await this.webApi.post(this.user, url, payload);
      if (retval.errorCode === -2) {
        this.router.navigate(["/login"]);
      } else {
        return retval;
      }
    } catch (error) {
      this.log.error(error);
    }
  }
  async AddToCollectionDispatchLoad(payload) {
    try {
      const url = this.config.getServerUrl() + "api/collection/AddToDispatchLoadPlan";
      return await this.webApi.post(this.user, url, payload);
    } catch (error) {
      this.log.error(error);
    }
  }

  async AddToDeliveryDispatchLoad(payload) {
    try {
      const url = this.config.getServerUrl() + "api/delivery/AddToDeliveryLoadPlan";
      return await this.webApi.post(this.user, url, payload);
    } catch (error) {
      this.log.error(error);
    }
  }

  async DeleteCollectionLoadPlan(loadId: number) {
    try {
      const url = this.config.getServerUrl() + "api/collection/DeleteDispatchLoadPlanner?LoadId=" + loadId;
      return await this.webApi.post(this.user, url, null);
    } catch (error) {
      this.log.error(error);
    }
  }
  async DeleteDeliveryLoadPlan(loadId: number) {
    try {
      const url = this.config.getServerUrl() + "api/delivery/DeleteDispatchLoadPlanner?LoadId=" + loadId;
      return await this.webApi.post(this.user, url, null);
    } catch (error) {
      this.log.error(error);
    }
  }

  async AssignDeliveryLoadToTransport(loadId: number, transportId: number,) {
    try {
      var url = this.config.getServerUrl() + "api/delivery/assignLoadToTransporter?LoadId=" + loadId + "&TransporterId=" + transportId;
      var response = await this.webApi.post(this.user, url, null);
      if (response.errorCode == 0) {
        return response.result;
      } else if (response.errorCode == -2) {
        this.router.navigate(["/login"]);
      } else {
        return null;
      }
    } catch (error) {
      this.log.error(error);
    }
    return null;
  }

  async AssignCollectionLoadToTransport(loadId: number, transportId: number,) {
    try {
      const url = this.config.getServerUrl() + "api/collection/assignLoadToTransporter?LoadId=" + loadId + "&TransporterId=" + transportId;
      return await this.webApi.post(this.user, url, null);
    } catch (error) {
      this.log.error(error);
    }
    return null;
  }

  async getLegalEntityById(contactId) {
    try {
      var url = this.config.getServerUrl() + "api/LegalEntity/GetLegalEntityById?LegalEntityId=" + contactId;
      const LegalEntity = await this.webApi.post(this.user, url, null);
      if (LegalEntity.errorCode == 0) {
        return LegalEntity.result;
      } else if (LegalEntity.errorCode === -2) {
        this.router.navigate(["/login"]);
      } else {
        return undefined;
      }
    } catch (error) {
      this.log.error(error);
    }
  }
   async getBFJCIFRates(fromDate, toDate) {
    try {
      const url = this.config.getServerUrl() + "api/finance/rptBFJCIFRates?fromDate=" + fromDate + "&toDate=" + toDate;
      return await this.webApi.post(this.user, url, null);
    } catch (error) {
      this.log.error(error);
    }
  }
  async getOutstandingAmountsAndArrearNotice() {
    try {
      const url = this.config.getServerUrl() + "api/summaryDashboard/getOutstandingFileBalancesAndArrearNotices";
      const retval = await this.webApi.get(this.user, url);
      if (retval.errorCode === 0) {
        return retval.result;
      } else if (retval.errorCode === -2) {
        this.router.navigate(["/login"]);
      } else {
        return undefined;
      }
    } catch (error) {
      this.log.error(error);
    }
  }
  
   async UpdateNotesForTypeComment(fileId, note) {
    try {
      const url = this.config.getServerUrl() + "api/summaryDashboard/UpdateNotesForOutstandingAmountAndArrearNotice?fileId=" + fileId + "&note=" + note;
      return await this.webApi.post(this.user, url, null);
    } catch (error) {
      this.log.error(error);
    }
  }
}