import { WhatsappApiService } from './services/api/whatsapp-api.service';
import { RouteBuilderComponent } from './components/routes/route-builder/route-builder.component';

import { CommonModule } from '@angular/common';
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations/";
import { LocationStrategy, PathLocationStrategy } from "@angular/common";
import { AppRoutes } from "./app.routes";
import { ReactiveFormsModule } from "@angular/forms";

import { ToastModule } from "primeng/toast";
import { TooltipModule } from "primeng/tooltip";
import { SelectButtonModule } from "primeng/selectbutton";
import { ProgressSpinnerModule } from "primeng/progressspinner";
import { ListboxModule } from "primeng/listbox";
import { MultiSelectModule } from "primeng/multiselect";
import { TabViewModule } from "primeng/tabview";
import { OrderListModule } from 'primeng/orderlist';

import { InputTextModule, ButtonModule, DialogModule } from "primeng";
import { SharedModule } from "primeng";
import { DropdownModule } from "primeng/dropdown";
import { ConfirmDialogModule } from "primeng";
import { TabMenuModule } from "primeng";
import { FileUploadModule } from "primeng";
import { AccordionModule } from "primeng";
import { ProgressBarModule } from "primeng";
import { EditorModule } from "primeng";
import { BlockUIModule } from "primeng";
import { AutoCompleteModule } from "primeng";
import { TreeTableModule } from "primeng";
import { SliderModule } from "primeng/slider";
import { TableModule } from "primeng/table";
import { CheckboxModule } from "primeng/checkbox";
import { FieldsetModule } from "primeng/fieldset";
import { MessagesModule } from "primeng/messages";
import { MessageModule } from "primeng/message";
import { PickListModule } from "primeng/picklist";
import { MenuModule } from "primeng/menu";
import { ContextMenuModule } from "primeng/contextmenu";
import { TieredMenuModule } from "primeng/tieredmenu";
import { PanelModule } from "primeng/panel";
import { DataViewModule } from "primeng/dataview";
import { CalendarModule } from "primeng/calendar";
import { VirtualScrollerModule } from "primeng/virtualscroller";
import { CardModule } from "primeng/card";
import { RadioButtonModule } from "primeng/radiobutton";
import { SpinnerModule } from "primeng/spinner";
import { SplitButtonModule } from "primeng/splitbutton";
import { OverlayPanelModule } from "primeng/overlaypanel";
import { ScrollPanelModule } from "primeng/scrollpanel";
import { InputSwitchModule } from "primeng/inputswitch";
import { StepsModule } from "primeng/steps";
import { MessageService } from "primeng/api";
import { SidebarModule } from "primeng/sidebar";
import { LightboxModule } from "primeng/lightbox";
import { ChartModule } from 'primeng/primeng';
import { InputMaskModule } from 'primeng/inputmask';
import { FullCalendarModule } from 'primeng/fullcalendar';

import { KeyFilterModule } from 'primeng/keyfilter';
import { GalleriaModule } from 'primeng/galleria';
import { InputNumberModule } from 'primeng/inputnumber';
import { ChipsModule } from 'primeng/chips';

import { AppComponent } from "./app.component";
import { AppMenuComponent } from "./app.menu.component";
import { AppSubMenuComponent } from "./app.submenu.component";
import { AppTopbarComponent } from "./app.topbar.component";
import { AppFooterComponent } from "./app.footer.component";
import { DisplayContactComponent } from "./components/contacts/contact.component";

import { FileUploadComponent } from "./components/file-upload/file-upload.component";
import { FileViewComponent } from "./components/file/file.component";
import { TaskListComponent } from "./components/task-list/task-list.component";
import { SearchResultComponent } from "./components/search/search-result/search-result.component";
import { LoginComponent } from "./components/login/login.component";
import { DocumentHistoryComponent } from "./components/file/document-history/document-history.component";
import { CargoComponent } from "./components/file/cargo/cargo.component";
import { ShippingInfoComponent } from "./components/file/shipping-Info/shipping-info.component";
import { DispatchManagerComponent } from "./components/dispatch-manager/dispatch-manager.component";
import { CorrespondenceComponent } from "./components/file/correspondence/correspondence.component";
import { ClientPopupComponent } from "./components/file/client-popup/client-popup.component";
import { ContactNotesComponent } from "./components/contacts/notes/contact.notes.component";
import { NotesComponent } from "./components/file/notes/notes.component";
import { InspectionComponent } from "./components/file/inspection/inspection.component";
import { CommunicatorComponent } from "./components/communicator/communicator.component";
import { OpenFileComponent } from "./components/file-open/file-open.component";
import { BulkUpdaterComponent } from "./components/bulk-updater/bulk-updater.component";
import { ShippingLinesComponent } from "./components/reference/shipping-lines/shipping-lines.component";

// Reference Table Edits
import { ManageColorsComponent } from "./components/reference/colors/colors.component";
import { ManageInvoiceItemsComponent } from "./components/reference/manage-invoice-items/manage-invoice-items.component";
import { ManageBorderPostComponent } from "./components/reference/border-post/border-post.component";
import { ManageDocumentCategoryComponent } from "./components/reference/document-category/document-category.component";
import { ShippingDetailsComponent } from "./components/file-open/shipping-details/shipping-details.component";
import { ConsigneeDetailsComponent } from "./components/file-open/consignee-details/consignee-details.component";
import { FileItemsComponent } from "./components/file-open/file-items/file-items.component";
import { MasterBillComponent } from "./components/reference/manage-masterbill/masterbill.component";
import { ContainersComponent } from "./components/reference/containers/containers.component";
import { ContainerItemsComponent } from "./components/reference/containers/container-items.component";

//Components
import { LocationComponent } from "./components/location/location.component";

// Services
import { ConfigService } from "./services/config.service";
import { ApiService } from "./services/api.service";
import { Api2Service } from "./services/api2.service";
import { AuthManager } from "./services/api/auth/auth.manager";
import { WebApiService } from "./services/web.api.service";
import { WebApi2Service } from "./services/web.api2.service";
import { SearchService } from "./services/rxjs.service";
import { LogBase, LogService } from "./services/logger.service";
import { ConfirmationService } from "primeng/api";

// Workflow Components
import { ManageVesselsComponent } from "./components/reference/manage-vessels/manage-vessels.component";
import { ManageVehiclesComponent } from "./components/reference/manage-vehicles/manage-vehicles.component";
import { FileAssignmentComponent } from "./components/reference/file-assignment/file-assignment.component";

import { ImageViewerModule } from 'ng2-image-viewer';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { QRCodeModule } from 'angular2-qrcode';
import { FooterComponent } from './components/footer/footer.component';
import { WhatsappManagerComponent } from './components/whatsapp/whatsapp-manager/whatsapp-manager.component';
import { WhatsappHistoryComponent } from './components/whatsapp/whatsapp-history/whatsapp-history.component';
import { WhatsappDashboardComponent } from './components/whatsapp/whatsapp-dashboard/whatsapp-dashboard.component';
import { ManageLegalEntityComponent } from './components/contacts/manage-legal-entity/manage-legal-entity.component';
import { MonthlyCarMovementComponent } from './summary/monthly-car-movement/monthly-car-movement.component';
import { ShipArrivalsComponent } from './summary/ship-arrivals/ship-arrivals.component';
import { DeliveryStatsComponent } from './summary/delivery-stats/delivery-stats.component';
import { CarMakeAndTypeStatsComponent } from './summary/car-make-and-type-stats/car-make-and-type-stats.component';
import { SummaryDashboardComponent } from './summary-dashboard/summary-dashboard.component';
import { RouteStatusUpdaterComponent } from './components/routes/route-status-updater/route-status-updater.component';
import { FilterSearchComponent } from './components/filter-search/filter-search.component';
import { FilterApiService } from './services/api/filter-api.service';
import { FilterDataService } from './components/filter-search/filter-search-data.service';
import { AnyReportComponent } from './components/on-demand/any-report/any-report.component';
import { AnyJobComponent } from './components/on-demand/any-job/any-job.component';
import { CriteriaComponent } from './components/on-demand/criteria/criteria.component';
import { AddRowDirective } from './summary/ship-arrivals/add-row.directive';
import { AnyViewTableComponent } from './components/on-demand/any-view-table/any-view-table.component';

import { InvoiceApiService } from './services/api/finance/invoice-api.service';
import { AllocatePaymentComponent } from './components/user-tasks/finance/allocate-payment/allocate-payment.component';

import { DynamicTableComponent } from './components/on-demand/any-view-table/dynamic-table/dynamic-table.component';
import { DynamicTableBasicComponent } from './components/on-demand/any-view-table/dynamic-table-basic/dynamic-table-basic.component';
import { BracketsComponent } from './components/on-demand/any-view-table/dynamic-table/brackets/brackets.component';
import { WhatsappComponent } from './iframes/whatsapp/whatsapp.component';
import { SaexComponent } from './iframes/saex/saex.component';
import { AvecsFinanceComponent } from './iframes/avecs-finance/avecs-finance.component';
import { FinanceSummaryComponent } from './components/finance/finance-summary/finance-summary.component';
import { FileNumberComponent } from './components/file-number/file-number.component';
import { AddClearingAgentComponent } from './components/user-tasks/shared/add-clearing-agent/add-clearing-agent.component';
import { VesselBoardComponent } from './summary/vessel-board/vessel-board.component';
import { StorageInvoiceComponent } from './components/finance/storage-invoice/storage-invoice.component';
import { StorageFormComponent } from './components/finance/storage-invoice/storage-form/storage-form.component';
import { AuthenticationService } from './services/api/auth/authentication.service';
import { DispatchPlanningComponent } from './components/dispatch-manager/dispatch-planning/dispatch-planning.component';
import { CheckBorderPostComponent } from './components/user-tasks/file-owner/check-border-post/check-border-post.component';
import { WhatsappTalkToAgentGeneralComponent } from './components/user-tasks/whatsapp/whatsapp-talk-to-agent-general/whatsapp-talk-to-agent-general.component';
import { WhatsappTalkToAgentForAFileComponent } from './components/user-tasks/file-owner/whatsapp-talk-to-agent-for-a-file/whatsapp-talk-to-agent-for-a-file.component';
import { UserTaskDashboardComponent } from './summary/user-task-dashboard/user-task-dashboard.component';
import { CarsInDurbanComponent } from './summary/cargo-tracker/cars-in-durban.component';

import { WhatsappChatsComponent } from './components/whatsapp/whatsapp-chats/whatsapp-chats.component';
import { InvoiceCreateComponent } from './components/finance/invoice/invoice-create/invoice-create.component';
import { InvoiceEditComponent } from './components/finance/invoice/invoice-edit/invoice-edit.component';
import { InvoiceFormComponent } from './components/finance/invoice/invoice-form/invoice-form.component';
import { BfjCifComponent } from './components/finance/bfj-cif/bfj-cif.component';
import { AdhocUserTaskComponent } from './components/user-tasks/adhoc/adhoc-user-task/adhoc-user-task.component';
import { WhatsappProductComponent } from './components/whatsapp/whatsapp-product/whatsapp-product.component';
import { LegassignmentComponent } from './components/file/legassignment/legassignment.component';
import { WhatsappCheckDocumentComponent } from './components/user-tasks/whatsapp/whatsapp-check-document/whatsapp-check-document.component';
import { FileIssuesPopUpComponent } from './components/file-issues-pop-up/file-issues-pop-up.component';
import { FixStorageInvoiceIssuesComponent } from './components/finance/storage-invoice/fix-storage-invoice-issues/fix-storage-invoice-issues.component';
import { CollectionPlanningComponent } from './components/dispatch-manager/collection-planning/collection-planning.component';
import { CargoTableComponent } from './components/dispatch-manager/cargo-table/cargo-table.component';
import { MorningPageComponent } from './summary/morning-page/morning-page.component';
import { ApproveRejectAdminTransactionComponent } from './components/user-tasks/finance/approve-reject-admin-transaction/approve-reject-admin-transaction.component';
import { InspectionRouteUpdateFailComponent } from './components/user-tasks/file-owner/inspection-route-update-fail/inspection-route-update-fail.component';
import { PreplanningComponent } from './components/dispatch-manager/preplanning/preplanning.component';
import { ManageFilePreferencesComponent } from './components/manage-file-preferences/manage-file-preferences.component';
import { FilePreferencesComponent } from './components/file/file-preferences/file-preferences.component';
import { FilePreferencesHistoryComponent } from './components/file/file-preferences-history/file-preferences-history.component';
import { OutstandingAmountArrearNoticesComponent } from './summary/outstanding-amount-arrear-notices/outstanding-amount-arrear-notices.component';
import { DispatchNotifyClientsReminderComponent } from './components/user-tasks/dispatch/dispatch-notify-clients-reminder/dispatch-notify-clients-reminder.component';

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    FormsModule,
    AppRoutes,
    BrowserAnimationsModule,
    ListboxModule,
    TabViewModule,
    OrderListModule,
    InputTextModule,
    ButtonModule,
    FontAwesomeModule,
    DialogModule,
    ReactiveFormsModule,
    SharedModule,
    DropdownModule,
    ConfirmDialogModule,
    TabMenuModule,
    FileUploadModule,
    AccordionModule,
    ProgressBarModule,
    SliderModule,
    EditorModule,
    BlockUIModule,
    AutoCompleteModule,
    MultiSelectModule,
    TreeTableModule,
    TableModule,
    CheckboxModule,
    FieldsetModule,
    MessagesModule,
    MessageModule,
    PickListModule,
    MenuModule,
    ContextMenuModule,
    TieredMenuModule,
    ProgressSpinnerModule,
    PanelModule,
    DataViewModule,
    CalendarModule,
    VirtualScrollerModule,
    CardModule,
    RadioButtonModule,
    SpinnerModule,
    SplitButtonModule,
    OverlayPanelModule,
    SelectButtonModule,
    ScrollPanelModule,
    InputSwitchModule,
    LightboxModule,
    TooltipModule,
    HttpClientModule,
    StepsModule,
    ToastModule,
    ImageViewerModule,
    SidebarModule,
    KeyFilterModule,
    QRCodeModule,
    GalleriaModule,
    InputNumberModule,
    ChipsModule,
    ChartModule,
    FullCalendarModule,
    InputMaskModule,
  ],
  declarations: [
    AppComponent,
    AppMenuComponent,
    AppSubMenuComponent,
    AppTopbarComponent,
    AppFooterComponent,
    DisplayContactComponent,
    FileUploadComponent,
    FileViewComponent,
    TaskListComponent,
    SearchResultComponent,
    LoginComponent,
    DocumentHistoryComponent,
    CargoComponent,
    ShippingInfoComponent,
    DispatchManagerComponent,
    CorrespondenceComponent,
    ClientPopupComponent,
    ContactNotesComponent,
    NotesComponent,
    InspectionComponent,
    CommunicatorComponent,
    ManageVesselsComponent,
    ShippingDetailsComponent,
    ConsigneeDetailsComponent,
    FileItemsComponent,
    LocationComponent,
    AddRowDirective,
    FixStorageInvoiceIssuesComponent,

    // reference tables
    ManageColorsComponent,
    BulkUpdaterComponent,
    ManageBorderPostComponent,
    ManageDocumentCategoryComponent,
    ShippingLinesComponent,
    ManageInvoiceItemsComponent,
    OpenFileComponent,
    ManageVehiclesComponent,
    FooterComponent,
    RouteBuilderComponent,
    MasterBillComponent,
    ContainersComponent,
    ContainerItemsComponent,

    // whatsapp
    WhatsappManagerComponent,
    WhatsappHistoryComponent,
    WhatsappDashboardComponent,
    WhatsappProductComponent,
    // File
    FileAssignmentComponent,
    ManageLegalEntityComponent,
    MonthlyCarMovementComponent,
    ShipArrivalsComponent,
    DeliveryStatsComponent,
    CarMakeAndTypeStatsComponent,
    SummaryDashboardComponent,
    RouteStatusUpdaterComponent,
    FilterSearchComponent,
    AnyReportComponent,
    AnyJobComponent,
    CriteriaComponent,
    AnyViewTableComponent,
    AllocatePaymentComponent,
    DynamicTableComponent,
    DynamicTableBasicComponent,
    BracketsComponent,
    WhatsappComponent,
    SaexComponent,
    AvecsFinanceComponent,
    BfjCifComponent,
    FinanceSummaryComponent,
    FileNumberComponent,
    AddClearingAgentComponent,
    VesselBoardComponent,
    StorageInvoiceComponent,
    StorageFormComponent,
    CheckBorderPostComponent,
    InspectionRouteUpdateFailComponent,
    DispatchPlanningComponent,
    WhatsappTalkToAgentGeneralComponent,
    WhatsappTalkToAgentForAFileComponent,
    UserTaskDashboardComponent,
    CarsInDurbanComponent,
    InvoiceCreateComponent,
    InvoiceEditComponent,
    InvoiceFormComponent,
    WhatsappChatsComponent,
    AdhocUserTaskComponent,
    FixStorageInvoiceIssuesComponent,
    LegassignmentComponent,
    WhatsappCheckDocumentComponent,
    FileIssuesPopUpComponent,
    ApproveRejectAdminTransactionComponent,
    CollectionPlanningComponent,
    CargoTableComponent,
    MorningPageComponent,
    PreplanningComponent,
    ManageFilePreferencesComponent,
    FilePreferencesComponent,
    FilePreferencesHistoryComponent,
  OutstandingAmountArrearNoticesComponent,
  DispatchNotifyClientsReminderComponent
  ],
  providers: [
    { provide: LocationStrategy, useClass: PathLocationStrategy },
    { provide: LogBase, useClass: LogService },
    ConfigService,
    WebApiService,
    WebApi2Service,
    SearchService,
    ApiService,
    Api2Service,
    AuthManager,
    AuthenticationService,
    ConfirmationService,
    MessageService,
    WhatsappApiService,
    FilterApiService,
    FilterDataService,
    InvoiceApiService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
