<p-table [value]="routeLegAssignments">
    <ng-template pTemplate="header">
        <tr>
            <th>Leg</th>
            <th>Transporter</th>
            <th>Driver</th>
            <th>ETD</th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-leg>
        <tr>
            <td>{{ leg.leg }}</td>
            <td>{{ leg.name }}</td>
            <td>{{ leg.driverName }}</td>
            <td>{{ leg.etd }}</td>
        </tr>
    </ng-template>
</p-table>